import './input.css';

import { useEffect, useRef } from 'react';
import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../recoil/atoms/userConfigState';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

import { translate } from '../../../common/language/translations';
import { getFormIcon, getLocalityIcon, getPlayingTimeIcon, getRoleIcon } from '../../utils/iconUtils';
import { getClubLightColor } from '../../utils/configUtils';
import { countryCodeToCountryInfo } from '../../static/countries';


interface DropDownSelectWithIconsProps {
  id: string;

  dropDownOptions: string[]; // static language keys
  selectedOptions: string[];
  setSelectedOptions: (value: string[]) => void;
  isDropDownExpanded: boolean;
  setIsDropDownExpanded: (value: boolean) => void;

  defaultDropDownText: string; // static language key
  defaultDropDownTextColor: string;
  marginBetweenOptions: number;
  height?: number;
  maxHeight?: string; // controls scrolling
  multiSelect?: boolean;

  useRoleIcons?: boolean;
  useLocalityIcons?: boolean;
  useCountryFlags?: boolean;
  usePlayingTimeIcons?: boolean;
  useFormIcons?: boolean;
}

export const DropDownSelectWithIcons: React.FC<DropDownSelectWithIconsProps> = ({
  id,

  dropDownOptions,
  selectedOptions,
  setSelectedOptions,
  isDropDownExpanded,
  setIsDropDownExpanded,

  defaultDropDownText,
  defaultDropDownTextColor,
  marginBetweenOptions,
  height,
  maxHeight,
  multiSelect,

  useRoleIcons,
  useLocalityIcons,
  useCountryFlags,
  usePlayingTimeIcons,
  useFormIcons,
}) => {

  const userConfig = useRecoilValue(userConfigState);

  const dropDownRef = useRef<HTMLDivElement>(null);


  const handleDropDownClick = () => {
    if (isDropDownExpanded) {
      removeDropDownExpansion();
    }

    else {
      expandDropDown();
    }
  };


  const handleOptionSelect = (option: string, isOptionAlreadySelected: boolean) => {

    let newSelectedOptions: string[] = [];

    // single-select
    if (!multiSelect) {
      if (!isOptionAlreadySelected) {
        removeDropDownExpansion();
      }
      newSelectedOptions = isOptionAlreadySelected ? [] : [option];
    }

    // multi-select
    else {
      newSelectedOptions = selectedOptions.slice();
      if (isOptionAlreadySelected) {
        const i = newSelectedOptions.indexOf(option);
        newSelectedOptions.splice(i, 1);
      }
      else {
        newSelectedOptions.push(option);
      }
    }

    setSelectedOptions(newSelectedOptions);
  };


  const expandDropDown = () => {
    const element = document.getElementById(id);
    if (element) {
      element.style.transition = 'height 150ms';
      element.style.height = 'auto';
      element.style.zIndex = '100';
    }
    setIsDropDownExpanded(true);
  };


  const removeDropDownExpansion = () => {
    const element = document.getElementById(id);
    if (element) {
      element.style.transition = '75ms';
      element.style.height = height ? height + 'px' : '28px';
      element.style.zIndex = '1';
    }
    setIsDropDownExpanded(false);
    if (dropDownRef.current) {
      dropDownRef.current.scrollTop = 0;
    }
  };


  const getIcon = (option: string) => {

    if (useCountryFlags) {
      return (
        <div>
          <img
            className='drop-down-select-option-with-icons-flag'
            src={countryCodeToCountryInfo[option].flagUrl}
            alt={option}
          />
        </div>
      );
    }

    return useRoleIcons
      ? getRoleIcon(option, '#000000cc', 18, false, clubLightColor)
      : useLocalityIcons
        ? getLocalityIcon(option, '#000000cc', 18)
        : usePlayingTimeIcons
          ? getPlayingTimeIcon(option, '#000000cc', 18)
          : useFormIcons
            ? getFormIcon(option, '#000000cc', 18)
            : null;
  };


  const getDisplayOption = (option: string) => {
    if (!userConfig) return '';

    if (useCountryFlags) {
      return countryCodeToCountryInfo[option].name[userConfig.language];
    }

    if (useLocalityIcons) {
      return translate(option + 'Locality', userConfig.language);
    }

    return translate(option, userConfig.language);
  };


  const showSelectedOptions = () => {
    return (
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        {selectedOptions.map(option => {
          return (
            <div key={option} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              {getIcon(option)}

              {selectedOptions.length > 1 && selectedOptions.indexOf(option) < selectedOptions.length - 1 && (
                <div className='drop-down-select-with-icons-selected-option-divider' />
              )}

              {selectedOptions.length === 1 && (
                <div style={{ marginLeft: 4 }}>
                  {getDisplayOption(option)}
                </div>
              )}

            </div>
          );
        })}
      </div>
    );
  };


  useEffect(() => {
    if (!isDropDownExpanded) {
      removeDropDownExpansion();
    }
  }, [isDropDownExpanded]); // eslint-disable-line react-hooks/exhaustive-deps


  const clubLightColor = getClubLightColor(userConfig?.club ?? '');


  return (
    <div
      className={'drop-down-select-container' + ((isDropDownExpanded || selectedOptions.length > 0) ? ' drop-down-select-container-focus' : '')}
      id={id}
      style={{ height: height, maxHeight: maxHeight }}
    >

      <div
        className='drop-down-select-button'
        style={{ height: height }}
        onClick={() => handleDropDownClick()}
      >

        {selectedOptions.length > 0 && <div className='drop-down-select-button-overlay'>&nbsp;</div>}

        <div className='drop-down-select-button-text'>
          {selectedOptions.length === 0 ? (
            <div style={{ color: defaultDropDownTextColor }}>
              {translate(defaultDropDownText, userConfig?.language)}
            </div>
          ) : (
            <div style={{ color: '#000000' }}>
              {showSelectedOptions()}
            </div>
          )}
        </div>

        {!isDropDownExpanded && (
          <div className='drop-down-select-icon' style={{ marginTop: height ? (height - 24) / 2 : undefined }}>
            <ArrowDropDownIcon style={{ fontSize: 24 }} />
          </div>
        )}

        {isDropDownExpanded && (
          <div className='drop-down-select-icon' style={{ marginTop: height ? (height - 24) / 2 : undefined }}>
            <ArrowDropUpIcon style={{ fontSize: 24 }} />
          </div>
        )}
      </div>

      <div className='drop-down-select-space'>&nbsp;</div>

      <div
        ref={dropDownRef}
        className={'drop-down-select-options' + (maxHeight ? ' drop-down-select-options-scroll' : '')}
        style={{ maxHeight: maxHeight }}
      >
        {dropDownOptions.map(option => {
          const isSelected = selectedOptions.includes(option);
          const icon = getIcon(option);

          return (
            <div
              className={'drop-down-select-option' + (isSelected ? ' drop-down-select-option-selected' : '')}
              style={{ marginTop: marginBetweenOptions }}
              key={option}
              onClick={() => handleOptionSelect(option, isSelected)}
            >
              <div className='drop-down-select-option-with-icons'>
                <div style={{ marginLeft: '3%', marginRight: '3%' }}>
                  {icon}
                </div>
                {getDisplayOption(option)}
              </div>
            </div>
          );
        })}
      </div>

    </div>
  );
};

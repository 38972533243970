import { playerTeamDataSelector } from '../../../recoil/selectors/playerTeamDataSelector';

import LockIcon from '@mui/icons-material/Lock';
import BlockIcon from '@mui/icons-material/Block';
import BuildIcon from '@mui/icons-material/Build';

import { getMonthsLeftOfContract, getDisplayPlayerName, getYearShort } from '../../../utils/playerUtils';
import { CellProps } from 'react-table';
import { StringToAnyMap } from '../../../types';
import { countryCodeToCountryInfo } from '../../../static/countries';
import { LazyImage } from '../../../../common/components/LazyImage';
import { getRoleIcon, getTeamIcon } from '../../../utils/iconUtils';
import { useRecoilValue } from 'recoil';
import { getClubLightColor } from '../../../utils/configUtils';

import {
  getAgeIndexEquivalent,
  getContractExpirationIndexEquivalent,
  getIndexColor
} from '../../../utils/colorUtils';


const renderInfoCell = (disablePlayersWithoutEventData: boolean, isGoalkeeper?: boolean) => {
  const InfoCellRenderer = ({ row }: StringToAnyMap) => {
    return <InfoCell row={row} disablePlayersWithoutEventData={disablePlayersWithoutEventData} isGoalkeeper={isGoalkeeper} />;
  };

  InfoCellRenderer.displayName = 'InfoCellRenderer';
  return InfoCellRenderer;
};


const InfoCell: React.FC<{ row: StringToAnyMap, disablePlayersWithoutEventData: boolean, isGoalkeeper?: boolean }> = ({
  row,
  disablePlayersWithoutEventData,
  isGoalkeeper
}) => {

  const disablePlayer = (disablePlayersWithoutEventData && !row.original['event_data_available'])
    || (isGoalkeeper !== undefined && (isGoalkeeper === (row.original['primary_position'] !== 'GK'))); // todo: positions

  return (
    <div className='player-simple-table-info-cell'>

      <LazyImage
        src={row.original.image_url ?? 'https://media.api-sports.io/football/players/0.png'}
        alt=''
        imageClassName='player-simple-table-info-cell-image'
        imageContainerClassName='player-simple-table-info-cell-image-container'
      />

      <div className='player-simple-table-info-cell-column'>

        <div className='player-simple-table-info-cell-row'>
          <div className='player-simple-table-info-cell-name' style={{ color: disablePlayer ? '#4e4e4e' : 'none' }}>
            {getDisplayPlayerName(row.original.fullname, 18)}
          </div>

          {row.original.country_code in countryCodeToCountryInfo && (
            <img
              className='player-simple-table-info-cell-flag'
              src={countryCodeToCountryInfo[row.original.country_code]?.flagUrl}
              alt=''
              draggable={false}
            />
          )}
        </div>

        <div className='player-simple-table-info-cell-row'>
          {row.original.club?.logo_url && (
            <div className='player-simple-table-club-logo-container'>
              <img
                className='player-simple-table-info-cell-club-logo'
                src={row.original.club?.logo_url}
                alt=''
                draggable={false}
              />
            </div>
          )}
          {row.original.club?.name && (
            <div className='player-simple-table-info-cell-info' style={{ color: disablePlayer ? '#4e4e4e' : 'none' }}>
              {row.original.club?.name}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};


const ImageCell: React.FC<CellProps<StringToAnyMap, string>> = ({ value }) => {
  return (
    <div className='player-simple-table-image-cell'>
      <img
        src={value ?? 'https://media.api-sports.io/football/players/0.png'}
        alt='' className='player-simple-table-image'
        style={{ height: 31 }}
        draggable={false}
      />
    </div>
  );
};


const SmallImageCell: React.FC<CellProps<StringToAnyMap, string>> = ({ value }) => {
  return (
    <div className='player-simple-table-image-cell'>
      <img
        src={value ?? 'https://media.api-sports.io/football/players/0.png'}
        alt=''
        className='player-simple-table-image'
        style={{ height: 23 }}
        draggable={false}
      />
    </div>
  );
};


const renderValueCell = (
  property: string,
  valueTransformer?: (value: any) => any, // eslint-disable-line @typescript-eslint/no-explicit-any
  isColored?: boolean,
  isOwnTeam?: boolean,
  isAcademyTeam?: boolean,
  fontWeight?: number,
  isSmallCell?: boolean
) => {
  const ValueCellRenderer = ({ row }: StringToAnyMap) => {
    return (
      <ValueCell
        row={row}
        property={property}
        valueTransformer={valueTransformer}
        isColored={isColored}
        isOwnTeam={isOwnTeam}
        isAcademyTeam={isAcademyTeam}
        fontWeight={fontWeight}
        isSmallCell={isSmallCell}
      />
    );
  };

  ValueCellRenderer.displayName = `ValueCellRenderer(${property})`;
  return ValueCellRenderer;
};


interface ValueCellProps {
  row: StringToAnyMap;
  property: string;
  valueTransformer?: (value: any) => any; // eslint-disable-line @typescript-eslint/no-explicit-any
  isColored?: boolean;
  isOwnTeam?: boolean;
  isAcademyTeam?: boolean;
  fontWeight?: number;
  isSmallCell?: boolean;
}

const ValueCell: React.FC<ValueCellProps> = ({
  row,
  property,
  valueTransformer,
  isColored,
  isOwnTeam,
  isAcademyTeam,
  fontWeight,
  isSmallCell,
}) => {

  let value = row.original[property];

  if (valueTransformer) {
    value = valueTransformer(value);
  }

  if (isSmallCell) {
    return (
      <div className='player-simple-table-cell-container' style={{ height: 25 }}>
        <div className='player-simple-table-cell-fullname' style={{ fontSize: 12, fontWeight: 500 }}>{value}</div>
      </div>
    );
  }

  const className = property === 'fullname'
    ? 'player-simple-table-cell-fullname' + (isOwnTeam ? (' player-simple-table-cell-fullname-' + row.original.role) : '')
    : 'player-simple-table-cell-info';

  const backgroundColor = isColored
    ? (
      (property === 'age' || property === 'birth_date')
        ? getIndexColor(
          getAgeIndexEquivalent(row.original['age'], isAcademyTeam),
          69)
        : (property === 'contract_expiration'
          ? getIndexColor(
            getContractExpirationIndexEquivalent(row.original['contract_expiration']),
            69
          )
          : getIndexColor(value, 69))
    )
    : undefined;

  return (
    <div
      className='player-simple-table-cell-container'
      style={{ backgroundColor: backgroundColor }}
    >
      <div className={className} style={{ fontWeight: fontWeight ?? 500 }}>{value}</div>
    </div>
  );
};


const FlagCell: React.FC<CellProps<StringToAnyMap, string>> = ({ value }) => {
  const flagUrl = value ? countryCodeToCountryInfo[value]?.flagUrl : undefined;

  if (!flagUrl) return <div>&nbsp;</div>;

  return (
    <div className='player-simple-table-cell-container'>
      <img
        className='player-simple-table-flag'
        src={flagUrl}
        alt=''
        draggable={false}
      />
    </div>
  );
};


const ClubLogoCell: React.FC<CellProps<StringToAnyMap, string>> = ({ value }) => {

  if (!value) return <div>&nbsp;</div>;

  return (
    <div className='player-simple-table-cell-container'>
      <img
        className='player-simple-table-club-logo'
        src={value}
        alt=''
        draggable={false}
      />
    </div>
  );
};


const renderRoleIconCell = (club: string, isColored?: boolean) => {
  const RoleCellRenderer = ({ row }: StringToAnyMap) => {
    return <RoleIconCell row={row} club={club} isColored={isColored} />;
  };

  RoleCellRenderer.displayName = 'RoleIconCellRenderer';
  return RoleCellRenderer;
};

const RoleIconCell: React.FC<{ row: StringToAnyMap, club: string, isColored?: boolean }> = ({ row, club, isColored }) => {

  const iconColor = isColored ? '#000000' : '#353a48';
  const clubLightColor = isColored ? undefined : getClubLightColor(club);

  const icon = getRoleIcon(row.original.role, iconColor, undefined, undefined, clubLightColor);

  return (
    <div className='player-simple-table-cell-container'>
      {icon}
    </div>
  );
};


const renderTeamIconCell = (disablePlayersWithoutEventData?: boolean, isGoalkeeper?: boolean) => {
  const IconCellRenderer = ({ row }: StringToAnyMap) => {
    return <TeamIconCell row={row} disablePlayersWithoutEventData={disablePlayersWithoutEventData} isGoalkeeper={isGoalkeeper} />;
  };

  IconCellRenderer.displayName = 'TeamIconCellRenderer';
  return IconCellRenderer;
};

const TeamIconCell: React.FC<{ row: StringToAnyMap, disablePlayersWithoutEventData?: boolean, isGoalkeeper?: boolean }> = ({
  row,
  disablePlayersWithoutEventData,
  isGoalkeeper
}) => {

  const playerTeamData = useRecoilValue(playerTeamDataSelector) ?? {};

  if (disablePlayersWithoutEventData && !row.original['event_data_available']) {
    if (row.original['event_data_exist']) {
      return (
        <div className='player-simple-table-icon-container'>
          <LockIcon style={{ fontSize: 18, color: '#353a48' }} />
        </div>
      );
    }
    return (
      <div className='player-simple-table-icon-container'>
        <BlockIcon style={{ fontSize: 18, color: '#353a48' }} />
      </div>
    );
  }

  if (isGoalkeeper !== undefined && (isGoalkeeper === (row.original['primary_position'] !== 'GK'))) {
    return (
      <div className='player-simple-table-icon-container'>
        <BlockIcon style={{ fontSize: 18, color: '#353a48' }} />
      </div>
    );
  }

  const currentTeam = playerTeamData[row.original.id]?.currentTeam;
  const currentTeamIcon = getTeamIcon(currentTeam, '#353a48', 18);

  if (currentTeamIcon) {
    return (
      <div className='player-simple-table-icon-container'>
        {currentTeamIcon}
      </div>
    );
  }

  return <div>&nbsp;</div>;
};


const RoleConfigCell: React.FC<CellProps<StringToAnyMap, string>> = ({ value }) => {
  return (
    <div
      className='player-simple-table-cell-container'
      style={{ fontSize: 14, height: 28 }}
    >
      {value}
    </div>
  );
};


export const getPlayerSimpleColumns = (
  club: string,
  tableType: string | undefined, // undefined -> team
  teamMenu: StringToAnyMap,
  disablePlayersWithoutEventData?: boolean,
  isGoalkeeper?: boolean
) => {

  if (tableType === 'addPlayerView') {
    return addPlayerTable(disablePlayersWithoutEventData ?? false, isGoalkeeper);
  }

  if (tableType === 'addPlayerSimpleViewTeamIcon') {
    return addPlayerSimpleViewTable(club, false);
  }

  if (tableType === 'addPlayerSimpleViewRoleIcon') {
    return addPlayerSimpleViewTable(club, true);
  }

  if (tableType === 'ownTeamPlanner') {
    return ownTeamPlannerTable;
  }

  if (tableType === 'archivedTeam') {
    return archivedTeamTable;
  }

  if (tableType === 'archiveReportInfo') {
    return archiveReportInfoTable;
  }

  if (tableType === 'bench') {
    return benchTable(club);
  }

  if (tableType === 'roleConfigs') {
    return roleConfigsTable;
  }

  return teamViewPlayerTable(club, tableType, teamMenu);
};


const teamViewPlayerTable = (club: string, tableType: string | undefined, teamMenu: StringToAnyMap) => {

  const columns = [];


  if (teamMenu['role'] && teamMenu.showLeft === 'role') {
    columns.push({
      accessor: 'role',
      width: 24,
      Cell: renderRoleIconCell(club, teamMenu.colored === 'role'),
    });
  }

  columns.push({
    accessor: 'image_url',
    width: 32,
    Cell: ImageCell,
  });

  columns.push({
    accessor: 'fullname',
    width: teamMenu['shortNames'] ? 114 : 138,
    Cell: renderValueCell(
      'fullname',
      teamMenu['shortNames']
        ? (value: string) => getDisplayPlayerName(value, 10)
        : (value: string) => getDisplayPlayerName(value, 18),
      false,
      tableType === 'ownTeam'
    )
  });

  if (teamMenu['role'] && teamMenu.showLeft !== 'role') {
    columns.push({
      accessor: 'role',
      width: 24,
      Cell: renderRoleIconCell(club, teamMenu.colored === 'role'),
    });
  }

  if (teamMenu['country_code']) {
    columns.push({
      accessor: 'country_code',
      width: 21,
      Cell: FlagCell,
    });
  }

  if (teamMenu['club_logo_url']) {
    columns.push({
      accessor: 'club_logo_url',
      width: 21,
      Cell: ClubLogoCell,
    });
  }

  if (teamMenu['birth_date']) {
    columns.push({
      accessor: 'birth_date',
      width: 21,
      Cell: renderValueCell(
        'birth_date',
        getYearShort,
        teamMenu.colored === 'birth_date',
        undefined,
        tableType === 'academyTeam'
      ),
    });
  }

  if (teamMenu['age']) {
    columns.push({
      accessor: 'age',
      width: 21,
      Cell: renderValueCell(
        'age',
        (value: string) => value ? Math.round(Number(value)) : undefined,
        teamMenu.colored === 'age',
        undefined,
        tableType === 'academyTeam'
      ),
    });
  }

  if (teamMenu['contract_expiration']) {
    columns.push({
      accessor: 'contract_expiration',
      width: 21,
      Cell: renderValueCell(
        'contract_expiration',
        getMonthsLeftOfContract,
        teamMenu.colored === 'contract_expiration'
      ),
    });
  }

  if (teamMenu['skill_index']) {
    columns.push({
      accessor: 'skill_index',
      width: 26,
      Cell: renderValueCell(
        'skill_index',
        (value: string) => value !== undefined && value !== null ? Math.round(Number(value) * 10) / 10 : undefined,
        teamMenu.colored === 'skill_index'
      ),
    });
  }

  if (teamMenu['role_index']) {
    columns.push({
      accessor: 'role_index',
      width: 26,
      Cell: renderValueCell(
        'role_index',
        (value: string) =>
          value === 'beingComputed'
            ? <BuildIcon style={{ fontSize: 15, marginTop: 1, color: '#000000aa' }} />
            : value !== undefined && value !== null
              ? Math.round(Number(value) * 10) / 10
              : undefined,
        teamMenu.colored === 'role_index'
      ),
    });
  }

  if (shouldAddPaddingColumn(teamMenu)) {
    columns.push({
      accessor: 'rightPadding',
      width: rightPaddingWidth,
      Cell: () => null,
    });
  }

  return columns;
};


const addPlayerTable = (disablePlayersWithoutEventData: boolean, isGoalkeeper?: boolean) => [
  {
    accessor: 'id',
    width: 199,
    Cell: renderInfoCell(disablePlayersWithoutEventData, isGoalkeeper),
  },
  {
    accessor: 'currentTeam',
    width: 28,
    Cell: renderTeamIconCell(disablePlayersWithoutEventData, isGoalkeeper),
  },
];


const addPlayerSimpleViewTable = (club: string, useRoleIcon: boolean) => [
  {
    accessor: 'image_url',
    width: 32,
    Cell: ImageCell,
  },
  {
    accessor: 'fullname',
    width: 156,
    Cell: renderValueCell(
      'fullname',
      (value: string) => getDisplayPlayerName(value, 18),
      false,
      false,
      false,
      400
    ),
  },
  {
    accessor: 'id',
    width: 32,
    Cell: useRoleIcon ? renderRoleIconCell(club) : renderTeamIconCell(),
  },
];


const benchTable = (club: string) => [
  {
    accessor: 'image_url',
    width: 32,
    Cell: ImageCell,
  },
  {
    accessor: 'fullname',
    width: 148,
    Cell: renderValueCell('fullname', (value: string) => getDisplayPlayerName(value, 10)),
  },
  {
    accessor: 'id',
    width: 32,
    Cell: renderRoleIconCell(club),
  },
];


const ownTeamPlannerTable = [
  {
    accessor: 'image_url',
    width: 25,
    Cell: SmallImageCell,
  },
  {
    accessor: 'fullname',
    width: 115,
    Cell: renderValueCell(
      'fullname',
      (value: string) => getDisplayPlayerName(value, 10),
      undefined,
      undefined,
      undefined,
      undefined,
      true
    ),
  },
];


const archivedTeamTable = [
  {
    accessor: 'image_url',
    width: 32,
    Cell: ImageCell,
  },
  {
    accessor: 'fullname',
    width: 138,
    Cell: renderValueCell('fullname', (value: string) => getDisplayPlayerName(value, 16)),
  },
];


const archiveReportInfoTable = [
  {
    accessor: 'image_url',
    width: 34,
    Cell: ImageCell,
  },
  {
    accessor: 'fullname',
    width: 180,
    Cell: renderValueCell('fullname', (value: string) => getDisplayPlayerName(value, 22)),
  },
];


const roleConfigsTable = [
  {
    accessor: 'name',
    width: 125,
    Cell: RoleConfigCell,
  },
];


// we want to add a small padding to the right of icon columns (except role), if there is no other column to the right
const rightPaddingWidth = 2;
const anyNumericColumnSelected = (menu: StringToAnyMap) =>
  menu['role_index'] || menu['skill_index'] || menu['age'] || menu['birth_date'] || menu['contract_expiration'];

const anyIconColumnSelected = (menu: StringToAnyMap) =>
  menu['club_logo_url'] || menu['country_code'];

const shouldAddPaddingColumn = (menu: StringToAnyMap) =>
  anyIconColumnSelected(menu) && !anyNumericColumnSelected(menu);


export const getEmptyTableWidth = (tableType: string | undefined, teamMenu: StringToAnyMap) => {

  if (tableType === 'bench') {
    return 212;
  }

  else if (tableType === 'archivedTeam') {
    return 170;
  }

  else if (tableType === 'ownTeamPlanner') {
    return 140;
  }

  else if (tableType === 'roleConfigs') {
    return 125;
  }

  let baseWidth = 170;

  if (teamMenu.shortNames) baseWidth -= 24;
  if (teamMenu.role) baseWidth += 24;
  if (teamMenu.country_code) baseWidth += 21;
  if (teamMenu.club_logo_url) baseWidth += 21;
  if (teamMenu.birth_date) baseWidth += 21;
  if (teamMenu.age) baseWidth += 21;
  if (teamMenu.contract_expiration) baseWidth += 21;
  if (teamMenu.skill_index) baseWidth += 26;
  if (teamMenu.role_index) baseWidth += 26;
  if (shouldAddPaddingColumn(teamMenu)) baseWidth += rightPaddingWidth;

  return baseWidth;
};

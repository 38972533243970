import './playerView.css';

import { useState, useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { userConfigState } from '../../recoil/atoms/userConfigState';
import { selectedPlayerState } from '../../recoil/atoms/selectedPlayerState';
import { playersState } from '../../recoil/atoms/playersState';
import { teamsState } from '../../recoil/atoms/teamsState';
import { squadsState } from '../../recoil/atoms/squadsState';
import { competitionsState } from '../../recoil/atoms/competitionsState';
import { playerTeamDataSelector } from '../../recoil/selectors/playerTeamDataSelector';
import { playerSquadDataSelector } from '../../recoil/selectors/playerSquadDataSelector';
import { useGetAndAddPlayerOverview } from '../../recoil/hooks/useGetAndAddPlayerOverview';
import { useGetAndAddPlayerCareer } from '../../recoil/hooks/useGetAndAddPlayerCareer';
import { useOpenGlobalModal } from '../../recoil/hooks/useOpenGlobalModal';
import { useTrackEvent } from '../../services/server/analytics/useTrackEvent';
import { AuthContextType, useAuthContext } from '../../../common/contexts/AuthContext';

import Dialog from '@mui/material/Dialog';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ConstructionIcon from '@mui/icons-material/Construction';
import AddIcon from '@mui/icons-material/Add';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import TimelineIcon from '@mui/icons-material/Timeline';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import SummarizeIcon from '@mui/icons-material/Summarize';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import SsidChartIcon from '@mui/icons-material/SsidChart';
import EditIcon from '@mui/icons-material/Edit';
// import GraphicEqIcon from '@mui/icons-material/GraphicEq';
// import GraphicEqIcon from '@mui/icons-material/GraphicEq';
// import StackedLineChartIcon from '@mui/icons-material/StackedLineChart';
// import TrackChangesIcon from '@mui/icons-material/TrackChanges';
// import LineAxisIcon from '@mui/icons-material/LineAxis';

import { translate } from '../../../common/language/translations';
import { getClubColor } from '../../utils/configUtils';
import { StringToAnyMap, PlayerOverview, PlayerReport } from '../../types';
import { FixedTabLine } from '../tabLines/FixedTabLine';
import { PlayerViewOverview } from './PlayerViewOverview';
import { PlayerViewCompare } from './PlayerViewCompare';
import { PlayerViewCareer } from './PlayerViewCareer';
import { PlayerViewReports } from './PlayerViewReports';
import { getReportsForPlayer } from '../../services/firestore/reports';
import { PlayerHistoryModal } from '../modals/historyModals/PlayerHistoryModal';
import { getCompletePlayerHistory } from '../../services/firestore/activities';
import { staticTeamKeys } from '../../static/propertyValues';
import { countryCodeToCountryInfo } from '../../static/countries';
import { EditPlayerInfoModal } from '../modals/miscModals/EditPlayerInfoModal';
import { getRoleIcon, getTeamIcon } from '../../utils/iconUtils';
import { PlayerViewWithoutData } from './PlayerViewWithoutData';
import {
  getAge,
  getDateLocaleFormat,
  getDisplayCompetition,
  getDisplayPositions,
  getPlayerPropertyValue
} from '../../utils/playerUtils';


export const PlayerView: React.FC = () => {

  const { currentUser } = useAuthContext() as AuthContextType;
  const trackEvent = useTrackEvent();

  const userConfig = useRecoilValue(userConfigState);
  const competitions = useRecoilValue(competitionsState);

  const teams = useRecoilValue(teamsState);
  const squads = useRecoilValue(squadsState);

  const playerTeamData = useRecoilValue(playerTeamDataSelector);
  const playerSquadData = useRecoilValue(playerSquadDataSelector);

  const { openPlayerViewModal } = useOpenGlobalModal();

  const players = useRecoilValue(playersState);
  const [selectedPlayer, setSelectedPlayer] = useRecoilState(selectedPlayerState);
  const [playerOverview, setPlayerOverview] = useState<PlayerOverview | undefined>(selectedPlayer?.playerOverview);

  const getAndAddPlayerOverview = useGetAndAddPlayerOverview();
  const getAndAddClubIterationMap = useGetAndAddPlayerCareer();

  const [playerViewIsExpanding, setPlayerViewIsExpanding] = useState(true);

  const [reports, setReports] = useState<PlayerReport[]>([]);
  const [initialReport, setInitialReport] = useState<PlayerReport | undefined>(undefined);
  const [initialReportHasPrecedence, setInitialReportHasPrecedence] = useState(selectedPlayer?.initialReportId !== undefined);

  const [playerHistory, setPlayerHistory] = useState<StringToAnyMap[]>([]);

  const [isHistoryModalOpen, setIsHistoryModalOpen] = useState(false);

  const [isEditPlayerInfoModalOpen, setIsEditPlayerInfoModalOpen] = useState(false);
  const [editPlayerInfoProperty, setEditPlayerInfoProperty] = useState<string | undefined>(undefined);

  const [buttonHighlighted, setButtonHighlighted] = useState<string | undefined>(undefined);

  const [isConnectingPlayer, setIsConnectingPlayer] = useState(false);


  const isPlayerWithData = selectedPlayer !== undefined && !isNaN(Number(selectedPlayer.id));
  const isPlayerWithEventData = isPlayerWithData && playerOverview?.event_data_exist;
  const isPlayerWithEventDataAvailable = isPlayerWithEventData && playerOverview?.event_data_available;
  const isPlayerWithBasicStats = isPlayerWithData && playerOverview?.basic_stats_exist;

  const isPlayerWithoutData = selectedPlayer !== undefined && isNaN(Number(selectedPlayer.id));
  const isExistingPlayerWithoutData: boolean = isPlayerWithoutData && selectedPlayer.id in players;
  const isDeletedPlayerWithoutData: boolean = isPlayerWithoutData && !(selectedPlayer.id in players) && selectedPlayer.id !== 'newPlayerWithoutData';
  const isNewPlayerWithoutData: boolean = isPlayerWithoutData && !(selectedPlayer.id in players) && selectedPlayer.id === 'newPlayerWithoutData';

  const tabOptions = [
    'overview',
    // 'details',
    'compare',
    'career',
    'reports'
  ];

  const tabIcons = [
    <EqualizerIcon key={'equalizer-icon'} style={{ fontSize: 21 }} />,
    // <GraphicEqIcon key={'graphic-eq-icon'} style={{ fontSize: 21 }} />,
    <SsidChartIcon key={'ssid-icon'} style={{ fontSize: 21, marginTop: 0.5 }} />,
    <TimelineIcon key={'timeline-icon'} style={{ fontSize: 21 }} />,
    <SummarizeIcon key={'summarize-icon'} style={{ fontSize: 21 }} />
  ];

  const tabOptionToStatus = {
    'overview': (isPlayerWithoutData || isPlayerWithEventData)
      ? 'enabled'
      : 'blocked',
    'compare': isPlayerWithEventDataAvailable
      ? 'enabled'
      : isPlayerWithEventData
        ? 'locked'
        : 'blocked',
    'career': isPlayerWithBasicStats
      ? 'enabled'
      : 'blocked',
    'reports': 'enabled'
  };

  const initialActiveTab = selectedPlayer?.initialReportId
    ? 3
    : (isPlayerWithoutData || isPlayerWithEventData)
      ? 0
      : isPlayerWithBasicStats
        ? 2
        : 3;

  const [activeTab, setActiveTab] = useState(initialActiveTab);


  useEffect(() => {
    // console.log('playerOverview: ', playerOverview);
    if (selectedPlayer && !isNaN(Number(selectedPlayer.id)) && !playerOverview) {
      getAndAddPlayerOverview(selectedPlayer.id, currentUser).then((newPlayerOverview) => {
        setPlayerOverview(newPlayerOverview);
      });
    }
  }, [currentUser, selectedPlayer]); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    if (selectedPlayer && userConfig) {
      getAndAddClubIterationMap(selectedPlayer.id, currentUser);

      getReportsForPlayer(selectedPlayer.id, userConfig.club, selectedPlayer?.initialReportId).then((data) => {
        setReports(data.reports);
        setInitialReport(data.specificReport);
      });
    }
  }, [currentUser, selectedPlayer, userConfig?.club]); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    if (teams && userConfig?.club && selectedPlayer) {
      getCompletePlayerHistory(selectedPlayer.id, reports, userConfig.club).then((history) => {
        setPlayerHistory(history);
      });
    }
  }, [teams, reports, userConfig?.club, selectedPlayer]);


  const onClosePlayerModalFeedback = () => {
    setButtonHighlighted('statusButton');
    setTimeout(() => {
      setButtonHighlighted(undefined);
    }, 800);
  };


  const handleStatusButtonClick = () => {
    if (selectedPlayer && buttonHighlighted !== 'statusButton') {
      openPlayerViewModal(
        selectedPlayer.id,
        selectedPlayer.fullname,
        onClosePlayerModalFeedback,
        selectedPlayer?.squadId,
      );
    }
  };


  const handleEditInfoIconClick = (infoProperty: string) => {
    if (buttonHighlighted !== infoProperty) {
      setIsEditPlayerInfoModalOpen(true);
      setEditPlayerInfoProperty(infoProperty);
    }
  };


  const closeEditPlayerInfoModal = (infoProperty: string | undefined = undefined) => {
    setIsEditPlayerInfoModalOpen(false);

    if (infoProperty === 'role') {
      setTimeout(() => {
        setButtonHighlighted('role');
      }, 100);
      setTimeout(() => {
        setButtonHighlighted(undefined);
      }, 700);
    }
    else {
      setButtonHighlighted(infoProperty);
      setTimeout(() => {
        setButtonHighlighted(undefined);
      }, 800);
    }

    setTimeout(() => {
      setEditPlayerInfoProperty(undefined);
    }, 200);
  };


  const getDisplayName = () => {
    return playerOverview?.fullname ?? selectedPlayer?.fullname ?? '-';
  };


  const getStatusButtonText = () => {
    if (selectedPlayer?.squadId !== undefined) {
      if (playerSquadData && selectedPlayer && selectedPlayer.id in playerSquadData && selectedPlayer.squadId in playerSquadData[selectedPlayer.id]) {
        return squads ? squads[selectedPlayer.squadId]['name'] : '';
      }
      return buttonHighlighted !== 'statusButton' ? translate('addToSquad', userConfig?.language) : '';
    }
    if (playerTeamData && selectedPlayer && selectedPlayer.id in playerTeamData && userConfig) {
      return staticTeamKeys.includes(playerTeamData[selectedPlayer.id].currentTeam)
        ? translate(playerTeamData[selectedPlayer.id].currentTeam, userConfig.language)
        : (teams ? teams[playerTeamData[selectedPlayer.id].currentTeam]['name'] : '');
    }
    return buttonHighlighted !== 'statusButton' ? translate('addToTeam', userConfig?.language) : '';
  };


  const getStatusButtonIcon = () => {
    if (selectedPlayer?.squadId !== undefined) {
      if (playerSquadData && selectedPlayer && selectedPlayer.id in playerSquadData && selectedPlayer.squadId in playerSquadData[selectedPlayer.id]) {
        return <ConstructionIcon style={{ fontSize: 20 }} />;
      }
      return buttonHighlighted !== 'statusButton' ? <AddIcon style={{ fontSize: 20 }} /> : undefined;
    }
    if (playerTeamData && selectedPlayer && selectedPlayer.id in playerTeamData) {
      const currentTeam = playerTeamData[selectedPlayer.id].currentTeam;
      return getTeamIcon(currentTeam);
    }
    return buttonHighlighted !== 'statusButton' ? <AddIcon style={{ fontSize: 20 }} /> : undefined;
  };


  const getPlayerRoleIcon = () => {
    if (players && playerTeamData && selectedPlayer && selectedPlayer.id in playerTeamData) {
      const role = players[selectedPlayer.id]?.role;
      return getRoleIcon(role, buttonHighlighted === 'role' ? clubColor : '#ffffffd7', 20, true);
    }
  };


  const handleSetActiveTab = (index: number) => {
    if (index !== activeTab) {
      trackEvent('SubTabClicked', { parentTab: 'playerView', tab: tabOptions[index] });
    }

    setActiveTab(index);
    if (index !== 3) {
      setInitialReport(undefined);
      setInitialReportHasPrecedence(false);
    }
  };


  useEffect(() => {
    const timer = setTimeout(() => {
      setPlayerViewIsExpanding(false);
    }, 150);

    return () => {
      clearTimeout(timer);
    };
  }, []);


  const clubColor = getClubColor(userConfig?.club ?? '');


  return (
    <div className='player-view-section'>

      <Dialog
        open={isEditPlayerInfoModalOpen}
        onClose={() => closeEditPlayerInfoModal()}
        PaperProps={{ sx: { borderRadius: '0px' } }}>
        <EditPlayerInfoModal
          editPlayerInfoProperty={editPlayerInfoProperty}
          closeModal={closeEditPlayerInfoModal}
          playerToEdit={{
            ...selectedPlayer,
            contract_expiration: getPlayerPropertyValue('contract_expiration', playerOverview, selectedPlayer?.id, players),
            contract_expiration_in_database: playerOverview?.contract_expiration,
            birth_date: getPlayerPropertyValue('birth_date', playerOverview, selectedPlayer?.id, players),
            birth_date_in_database: playerOverview?.birth_date,
            role: getPlayerPropertyValue('role', undefined, selectedPlayer?.id, players),
            isPlayerWithoutData: isExistingPlayerWithoutData,
          }}
          isAcademyPlayer={
            (playerTeamData
              && selectedPlayer
              && selectedPlayer.id in playerTeamData
              && playerTeamData[selectedPlayer.id].currentTeam === 'academyTeam') ?? false
          }
        />
      </Dialog>

      <Dialog
        open={isHistoryModalOpen}
        onClose={() => setIsHistoryModalOpen(false)}
        PaperProps={{ sx: { borderRadius: '0px' } }}>
        <PlayerHistoryModal
          history={playerHistory}
          closeModal={() => setIsHistoryModalOpen(false)}
        />
      </Dialog>

      <ArrowBackIcon
        className='modal-icon player-view-exit-icon'
        style={{ fontSize: 26 }}
        onClick={() => setSelectedPlayer(undefined)}
      />


      <div className='player-view-header'>

        <div className='player-view-image-container'>
          {playerOverview &&
            <img src={playerOverview['image_url'] ?? 'https://media.api-sports.io/football/players/0.png'} alt='' className='player-view-image' draggable={false} />}

          {!playerOverview &&
            <img src={'https://media.api-sports.io/football/players/0.png'} alt='' className='player-view-image' draggable={false} />}
        </div>

        <div className='player-view-info-section'>

          <div className='player-view-name'>
            {getDisplayName()}

            {userConfig && playerTeamData && selectedPlayer && selectedPlayer.id in playerTeamData &&
              (playerTeamData[selectedPlayer.id].currentTeam === 'ownTeam' || playerTeamData[selectedPlayer.id].currentTeam === 'academyTeam') &&
              (
                <div className='player-view-role-icon-container' >
                  <div onClick={() => handleEditInfoIconClick('role')}>
                    {getPlayerRoleIcon()}
                  </div>

                  <div className='player-view-role-hover-text-container'>
                    <div className='player-view-icon-button-hover-text'>
                      {translate(
                        players && selectedPlayer && selectedPlayer.id in players && players[selectedPlayer.id].role
                          ? 'editTeamRole'
                          : 'addTeamRole',
                        userConfig.language
                      )}
                    </div>
                  </div>
                </div>
              )}
          </div>

          {userConfig && (
            <div className='player-view-info-row'>
              <div className='player-view-info-column player-view-info-age-column'>
                <div className='player-view-info-label'>
                  {translate('age', userConfig?.language)}
                </div>
                <div className='player-view-info-value player-view-info-value-row'>
                  {getPlayerPropertyValue('birth_date', playerOverview, selectedPlayer?.id, players, getAge, '-')}
                  {isExistingPlayerWithoutData && (
                    <div className='player-view-edit-info-icon' style={{ marginTop: -2 }}>
                      <EditIcon
                        style={{ fontSize: 16, color: buttonHighlighted === 'birth_date' ? clubColor : undefined }}
                        onClick={() => handleEditInfoIconClick('birth_date')}
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className='player-view-info-column'>
                <div className='player-view-info-label'>
                  {translate('position', userConfig?.language)}
                </div>
                <div className='player-view-info-value'>
                  {getDisplayPositions(playerOverview?.primary_positions, userConfig.language) ?? '-'}
                </div>
              </div>
              <div className='player-view-info-column'>
                <div className='player-view-info-label'>
                  {translate('nationality', userConfig?.language)}
                </div>
                <div className='player-view-info-value-row'>
                  {getPlayerPropertyValue('country_code', playerOverview, selectedPlayer?.id, players) &&
                    (
                      <img
                        className='player-view-flag'
                        src={countryCodeToCountryInfo[getPlayerPropertyValue('country_code', playerOverview, selectedPlayer?.id, players)].flagUrl}
                        alt=''
                        draggable={false}
                      />
                    )}
                  <div className='player-view-info-value player-view-info-value-row'>
                    {getPlayerPropertyValue('country_code', playerOverview, selectedPlayer?.id, players)
                      ? countryCodeToCountryInfo[getPlayerPropertyValue('country_code', playerOverview, selectedPlayer?.id, players)].name[userConfig.language]
                      : '-'}
                    {isExistingPlayerWithoutData && (
                      <div className='player-view-edit-info-icon' style={{ marginTop: -2 }}>
                        <EditIcon
                          style={{ fontSize: 16, color: buttonHighlighted === 'country_code' ? clubColor : undefined }}
                          onClick={() => handleEditInfoIconClick('country_code')}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className='player-view-info-column'>
                <div className='player-view-info-label'>
                  {translate('league', userConfig?.language)}
                </div>
                <div className='player-view-info-value'>{getDisplayCompetition(playerOverview?.club, competitions)}</div>
              </div>
              <div className='player-view-info-column'>
                <div className='player-view-info-label' style={{ display: 'flex', flexDirection: 'row' }}>
                  {translate('team', userConfig?.language)}

                  {playerOverview?.on_loan_from && (
                    <div style={{ marginLeft: 7, fontSize: 11, display: 'flex', flexDirection: 'row', marginTop: 1.5, color: '#ffffffdd' }}>
                      <div style={{ marginTop: -0.5 }}>
                        {'('}
                      </div>

                      {translate('loanFrom', userConfig.language, true)}

                      {playerOverview.on_loan_from && playerOverview.on_loan_from.logo_url && (
                        <div className='player-view-loan-club-logo-container'>
                          <img
                            className='player-view-loan-club-logo'
                            src={playerOverview.on_loan_from.logo_url}
                            alt=''
                            draggable={false}
                          />
                        </div>
                      )}

                      <div className='player-view-info-valuesfd'>
                        {playerOverview.on_loan_from?.name ?? '-'}
                      </div>

                      <div style={{ marginTop: -0.5 }}>
                        {')'}
                      </div>
                    </div>
                  )}
                </div>
                <div className='player-view-info-value-row'>
                  {playerOverview?.club && playerOverview.club.logo_url && (
                    <div className='player-view-club-logo-container'>
                      <img
                        className='player-view-club-logo'
                        src={playerOverview.club.logo_url}
                        alt=''
                        draggable={false}
                      />
                    </div>
                  )}
                  <div
                    className='player-view-info-value'
                    style={{ marginLeft: playerOverview?.club && playerOverview.club.logo_url ? 20 : undefined }}
                  >
                    {playerOverview?.club?.name ?? '-'}
                  </div>
                </div>
              </div>
              <div className='player-view-info-column'>
                <div className='player-view-info-label'>
                  {translate('height', userConfig?.language)}
                </div>
                <div className='player-view-info-value'>{getPlayerPropertyValue('height', playerOverview) ?? '-'}</div>
              </div>
              <div className='player-view-info-column player-view-info-contract-column'>
                <div className='player-view-info-label'>
                  {translate('contract', userConfig?.language)}
                </div>
                <div className='player-view-info-value'>
                  {getPlayerPropertyValue('contract_expiration', playerOverview, selectedPlayer?.id, players, (value) => getDateLocaleFormat(value), '-')}
                </div>
              </div>
            </div>
          )}
        </div>

        {(buttonHighlighted === 'contract_expiration' || (playerTeamData && selectedPlayer && selectedPlayer.id in playerTeamData &&
          (playerTeamData[selectedPlayer.id].currentTeam === 'ownTeam' || playerTeamData[selectedPlayer.id].currentTeam === 'academyTeam'))) &&
          (
            <div className='player-view-icon-button player-view-edit-contract-icon-button'>
              <div className='player-view-edit-contract-icon'>
                <EditCalendarIcon
                  style={{ fontSize: 18, color: buttonHighlighted === 'contract_expiration' ? clubColor : undefined }}
                  onClick={() => handleEditInfoIconClick('contract_expiration')}
                />
              </div>
              <div className='player-view-edit-contract-hover-text-container'>
                <div className='player-view-icon-button-hover-text'>
                  {translate('editContract', userConfig?.language)}
                </div>
              </div>
            </div>
          )}

        <div className='player-view-icon-button player-view-history-icon-button' onClick={() => setIsHistoryModalOpen(true)}>
          <div className='player-view-history-icon'>
            <LibraryBooksIcon style={{ fontSize: 20 }} />
          </div>
          <div className='player-view-history-hover-text-container'>
            <div className='player-view-icon-button-hover-text'>
              {translate('playerHistory', userConfig?.language)}
            </div>
          </div>
        </div>

        {!isDeletedPlayerWithoutData && (
          <div>
            <div className='player-view-team-status-text'>
              {translate(selectedPlayer?.squadId !== undefined ? 'squadStatus' : 'teamStatus', userConfig?.language)}
            </div>

            <div className='player-view-team-status-button-container'>
              <div
                className='player-view-team-status-button'
                style={{ backgroundColor: buttonHighlighted === 'statusButton' ? clubColor : '#fbfcfd' }}
                onClick={() => handleStatusButtonClick()}
              >
                <div className='player-view-team-status-button-text'>
                  {getStatusButtonText()}&nbsp;
                </div>

                <div className='player-view-team-status-button-icon'>
                  {getStatusButtonIcon()}
                </div>

                <div
                  className={
                    'player-view-team-status-button-overlay' +
                    (buttonHighlighted === 'statusButton' ? ' player-view-team-status-button-overlay-highlighted' : '')
                  }
                >
                  &nbsp;
                </div>
              </div>
            </div>
          </div>
        )}

        {isDeletedPlayerWithoutData && (
          <div className='player-view-team-status-text' style={{ top: 'calc(8vh - 10px)' }}>
            {/* todo: add restore button and functionality (?) */}
            {translate('deletedPlayer', userConfig?.language)}
          </div>

        )}

      </div>

      {!isNewPlayerWithoutData && (
        <FixedTabLine
          tabOptions={tabOptions}
          tabIcons={tabIcons}
          activeTab={activeTab}
          setActiveTab={handleSetActiveTab}
          initialActiveTab={initialActiveTab}
          marginTop={'13vh'}
          marginTopLine={'16.9vh'}

          isPlayerView={true}
          playerViewTabOptionToStatus={tabOptionToStatus}
        />
      )}

      {!initialReportHasPrecedence && isNewPlayerWithoutData && (
        <div className='player-view-deleted-player-text'>
          {translate('playerDoesNotExistYet', userConfig?.language)}
        </div>
      )}

      {activeTab === 0 && !initialReportHasPrecedence && isDeletedPlayerWithoutData && !isConnectingPlayer && (
        <div className='player-view-deleted-player-text'>
          {translate('playerIsDeleted', userConfig?.language)}
        </div>
      )}

      {activeTab === 0 && !initialReportHasPrecedence && isExistingPlayerWithoutData && (
        <PlayerViewWithoutData
          setIsConnectingPlayer={setIsConnectingPlayer}
        />
      )}

      {activeTab === 0 && !initialReportHasPrecedence && isPlayerWithEventData && (
        <PlayerViewOverview
          playerOverview={playerOverview}
        />
      )}

      {activeTab === 1 && !initialReportHasPrecedence && isPlayerWithEventData && (
        <PlayerViewCompare
          playerOverview={playerOverview}
        />
      )}

      {activeTab === 2 && !initialReportHasPrecedence && isPlayerWithBasicStats &&
        <PlayerViewCareer />
      }

      {activeTab === 3 && (
        <PlayerViewReports
          playerOverview={playerOverview}
          playerEntry={selectedPlayer ? { id: selectedPlayer.id, fullname: selectedPlayer.fullname } : undefined}

          reports={reports}
          setReports={setReports}

          playerViewIsExpanding={playerViewIsExpanding}
          initialReport={initialReport}
          initialReportHasPrecedence={initialReportHasPrecedence}
          setInitialReportHasPrecedence={setInitialReportHasPrecedence}
        />
      )}

    </div>
  );
};

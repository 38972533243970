import { clubConfigs } from '../static/clubConfigs';


export const getClubColor = (club: string) => {
  if (club in clubConfigs) {
    return clubConfigs[club]['color'];
  }
  return clubConfigs['fokus']['color'];
};


export const getClubSecondaryColor = (club: string) => {
  if (club in clubConfigs) {
    return clubConfigs[club]['secondaryColor'];
  }
  return clubConfigs['fokus']['secondaryColor'];
};


export const getClubLightColor = (club: string) => {
  if (club in clubConfigs) {
    return clubConfigs[club]['lightColor'];
  }
  return clubConfigs['fokus']['lightColor'];
};


export const getClubContrastColor = (club: string) => {
  if (club in clubConfigs) {
    return clubConfigs[club]['contrastColor'];
  }
  return clubConfigs['fokus']['contrastColor'];
};


export const getClubLogo = (club: string) => {
  if (club && club in clubConfigs) {
    return clubConfigs[club]['logo'];
  }
  return clubConfigs['fokus']['logo'];
};


export const getClubLogoFormat = (club: string) => {
  if (club && club in clubConfigs) {
    return clubConfigs[club]['logoFormat'];
  }
  return clubConfigs['fokus']['logoFormat'];
};


export const getClubLogoShadow = (club: string) => {
  if (club && club in clubConfigs) {
    return clubConfigs[club]['logoShadow'];
  }
  return clubConfigs['fokus']['logoShadow'];
};

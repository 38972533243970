import './login.css';

import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContextType, useAuthContext } from '../../../common/contexts/AuthContext';

import ErrorIcon from '@mui/icons-material/Error';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ReplayIcon from '@mui/icons-material/Replay';

import { FokusLogo } from '../../svg/FokusLogo';
import { translate } from '../../../common/language/translations';
// import { FokusLogoWithBackground } from '../../svg/FokusLogoWithBackground';


export const Login = () => {

  const { currentUser, login, resetPassword } = useAuthContext() as AuthContextType;

  const navigate = useNavigate();

  const [resetPasswordView, setResetPasswordView] = useState(false);

  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);

  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);


  function toggleViewChange() {
    setError('');
    setMessage('');
    setResetPasswordView(!resetPasswordView);
  }

  async function handleSignIn(event: React.FormEvent<HTMLFormElement>) {
    if (emailRef.current && passwordRef.current) {
      setError('');
      event.preventDefault();

      try {
        setError('');
        setLoading(true);
        await login(emailRef.current.value, passwordRef.current.value);

        navigate('/platform');
      } catch {
        setError('invalidLoginInput');
      }

      setLoading(false);
    }
  }

  async function handleResetPassword(event: React.FormEvent<HTMLFormElement>) {
    if (emailRef.current) {
      setError('');
      setMessage('');
      event.preventDefault();

      try {
        setMessage('');
        setError('');
        setLoading(true);
        await resetPassword(emailRef.current.value);

        setMessage('emailSent');
      } catch {
        setError('invalidEmail');
      }

      setLoading(false);
    }
  }


  useEffect(() => {
    if (currentUser) {
      navigate('/platform');
    }
  }, [currentUser, navigate]);


  return (
    <div className='view-container-base login-container'>

      <div className='login-section'>

        <div className='login-logo-section'>
          <div className='login-logo'>
            <FokusLogo whiteLogo={true} />
          </div>
          <div className='login-logo-text' style={{ fontFamily: 'Montserrat' }}>
            FOKUS
          </div>
        </div>

        {resetPasswordView && (
          <div className='login-box'>

            <div className='login-space'>&nbsp;</div>
            <div className='login-space'>&nbsp;</div>

            <form className='login-form' onSubmit={handleResetPassword}>

              {translate('email', 'en')}
              <input id='email' className='login-input' type='email' ref={emailRef} required />

              {error && (
                <div className='login-message'>
                  <div className='login-error-icon'>
                    <ErrorIcon style={{ fontSize: 26, color: '#1d1f28' }} />
                  </div>

                  {translate(error, 'en')}
                </div>
              )}

              {message && (
                <div className='login-message'>
                  <div className='login-error-icon'>
                    <CheckCircleIcon style={{ fontSize: 26, color: '#1d1f28' }} />
                  </div>
                  {translate(message, 'en')}
                </div>
              )}

              {!error && !message && <div className='login-empty-box'>&nbsp;</div>}

              <div className='login-button'>
                <input disabled={loading} className='login-submit' type='submit' value={translate('resetPassword', 'en')} />

                <div className='login-reset-icon'>
                  <ReplayIcon style={{ fontSize: 27, color: '#ffffff' }} />
                </div>
              </div>

            </form>

            <div className='login-space'>&nbsp;</div>
            <div className='login-space'>&nbsp;</div>
            <div className='login-space-small'>&nbsp;</div>

            <div className='login-link' onClick={() => toggleViewChange()}>
              {translate('login', 'en')}
            </div>

          </div>
        )}

        {!resetPasswordView && (
          <div className='login-box'>

            <form className='login-form' onSubmit={handleSignIn}>

              {translate('email', 'en')}
              <input id='email' className='login-input' type='email' ref={emailRef} required />

              {translate('password', 'en')}
              <input id='password' className='login-input' type='password' ref={passwordRef} required />

              {error && (
                <div className='login-message'>
                  <div className='login-error-icon'>
                    <ErrorIcon style={{ fontSize: 26, color: '#1d1f28' }} />
                  </div>
                  {translate(error, 'en')}
                </div>
              )}

              {!error && <div className='login-empty-box'>&nbsp;</div>}

              <div className='login-button'>
                <input disabled={loading} className='login-submit' type='submit' value={translate('login', 'en')} />
                <div className='login-icon'>
                  <ArrowRightAltIcon style={{ fontSize: 36, color: '#ffffff' }} />
                </div>
              </div>

            </form>

            <div className='login-link' onClick={() => toggleViewChange()}>
              {translate('forgotPassword?', 'en')}
            </div>

          </div>
        )}
      </div>

      {/* <div
        className='full-size-container'
        style={{ width: '86%', marginLeft: '7%' }}
      >
        <FokusLogoWithBackground />
      </div>

      <div
        className='full-size-container'
        style={{ position: 'absolute', background: 'linear-gradient(to bottom, #11161d 10%, #0e1318c8 20%, #0e1318d2 80%)' }}
      >
        &nbsp;
      </div>

      <div
        style={{ position: 'absolute', width: 250, left: 631.5, top: 176 }}
      >
        <FokusLogo whiteLogo={true} />
      </div> */}

    </div>
  );
};

import '../../platform.css';
import './home.css';

import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../recoil/atoms/userConfigState';
import { competitionsState } from '../../recoil/atoms/competitionsState';
import { useSetAndTrackSelectedPlayerState } from '../../recoil/hooks/useSetAndTrackSelectedPlayerState';
import { playerOverviewsState } from '../../recoil/atoms/playerOverviewsState';
import { teamsState } from '../../recoil/atoms/teamsState';
import { squadsState } from '../../recoil/atoms/squadsState';
import { useSetAndTrackNavigationPropsState } from '../../recoil/hooks/useSetAndTrackNavigationPropsState';
import { AuthContextType, useAuthContext } from '../../../common/contexts/AuthContext';

import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import RocketIcon from '@mui/icons-material/Rocket';
import EqualizerIcon from '@mui/icons-material/Equalizer';

import { translate } from '../../../common/language/translations';
import { getClubColor } from '../../utils/configUtils';
import { TeamTable } from '../../components/tables/teamTable/TeamTable';
import { FixedTabLine } from '../../components/tabLines/FixedTabLine';
import { PlatformHistory } from '../../components/history/PlatformHistory';
import { HomeViewLinePlot } from '../../components/plots/HomeViewLinePlot';
import { Toggle } from '../../components/input/Toggle';
import { StringToAnyMap, StringToStringMap, PlayerOverviews } from '../../types';
import { clubSettingsState } from '../../recoil/atoms/clubSettingsState';
import { getHomePageData } from '../../services/firestore/homePage';
import { getPlatformActivityLast14Days } from '../../services/firestore/activities';
import { getPlayerOverviews } from '../../services/server/application/playerOverviews';


export const tierColors: StringToStringMap = {
  '1': '#79bbc3',
  '2': '#c6a66c',
};


export const Home: React.FC = () => {

  const { currentUser } = useAuthContext() as AuthContextType;
  const setAndTrackNavigationProps = useSetAndTrackNavigationPropsState();

  const userConfig = useRecoilValue(userConfigState);
  const clubSettings = useRecoilValue(clubSettingsState);
  const competitions = useRecoilValue(competitionsState);
  const teams = useRecoilValue(teamsState);
  const squads = useRecoilValue(squadsState);
  const playerOverviews = useRecoilValue(playerOverviewsState);
  const setAndTrackSelectedPlayerState = useSetAndTrackSelectedPlayerState();

  const [activeLineTab, setActiveLineTab] = useState(0);
  const [activeTableTab, setActiveTableTab] = useState(0);

  const [tableDataArray, setTableDataArray] = useState<StringToAnyMap[][]>([]);
  const [tableOptionsArray, setTableOptionsArray] = useState<string[]>([]);
  const [lineData, setLineData] = useState<StringToAnyMap | undefined>(undefined);
  const [comparisonCompetitionIdToTierMap, setComparisonCompetitionIdToTierMap] = useState<StringToAnyMap>({});
  const [clubName, setClubName] = useState<string | undefined>(undefined);
  const [clubCountryCode, setClubCountryCode] = useState<string | undefined>(undefined);

  const [platformHistory, setPlatformHistory] = useState<StringToAnyMap[]>([]);
  const [additionalPlayerOverviews, setAdditionalPlayerOverviews] = useState<PlayerOverviews>({});

  const [isAdvancedTeamDataToggled, setIsAdvancedTeamDataToggled] = useState(false);


  const lineTabOptions = [
    <EqualizerIcon key={'equializer-icon'} style={{ fontSize: 21, marginTop: 1 }} />,
    <CalendarMonthIcon key={'calendar-icon'} style={{ fontSize: 21, marginTop: 1 }} />,
    <RocketIcon key={'rocket-icon'} style={{ fontSize: 21, marginTop: 1 }} />,
  ];
  const lineTabTitles = [
    'xGDataTitle',
    'averageAgePerMinutePlayedTitle',
    'u21MinutesPerMinuteTitle'
  ];


  const handlePlayerClick = (activity: StringToAnyMap, isReport: boolean) => {
    setAndTrackSelectedPlayerState({
      id: activity.playerId,
      fullname: activity.playerName,
      playerOverview: playerOverviews[activity.playerId] ?? additionalPlayerOverviews[activity.playerId],
      initialReportId: isReport ? activity.id : undefined,
    });
  };


  const handleSquadOrTeamClick = (teamOrSquadId: string, isSquad: boolean | undefined) => {
    if (teamOrSquadId === 'ownTeam' || teamOrSquadId === 'academyTeam') {
      setAndTrackNavigationProps({
        activeTab: 'ownTeam',
        activeSubTab: teamOrSquadId === 'academyTeam' ? 3 : 0,
      });
      return;
    }
    if (teamOrSquadId === 'masterTeam') {
      setAndTrackNavigationProps({
        activeTab: 'masterTeam',
      });
      return;
    }
    if (teamOrSquadId === 'shadowTeam') {
      setAndTrackNavigationProps({
        activeTab: 'shadowTeam',
      });
      return;
    }

    let isSquadInternal = isSquad;
    if (isSquadInternal === undefined) {
      isSquadInternal = squads?.[teamOrSquadId] ? true : false;
    }

    if (isSquadInternal) {
      setAndTrackNavigationProps({
        activeTab: 'squadBuilder',
        initialSquadOrTeamId: teamOrSquadId,
      });
      return;
    }
    else {
      setAndTrackNavigationProps({
        activeTab: 'scoutTeams',
        initialSquadOrTeamId: teamOrSquadId,
      });
      return;
    }
  };


  useEffect(() => {
    if (userConfig && clubSettings && competitions) {

      const tableNames: string[] = [];
      const tableIterationIds: number[] = [];
      const competitionIdsToCompareWith: Set<number> = new Set();

      if (clubSettings.iterations) {
        clubSettings.iterations.forEach((iteration: StringToAnyMap) => {
          const competition = competitions[iteration.competitionId];
          if (competition && competition.type === 'League') {
            const tableName = competition.name + ' ' + iteration.season;
            tableNames.push(tableName);
            tableIterationIds.push(iteration.iterationId);
            competitionIdsToCompareWith.add(Number(iteration.competitionId));
          }
        });
      }

      const competitionIdToTierMap: StringToAnyMap = {};
      competitionIdsToCompareWith.forEach((competitionId) => {
        competitionIdToTierMap[competitionId] = competitions[competitionId].tier;
      });

      getHomePageData(clubSettings.clubId, tableIterationIds, competitionIdsToCompareWith).then((homeViewData) => {
        setTableOptionsArray(tableNames);
        setTableDataArray(homeViewData.tableData);
        setLineData(homeViewData.lineData);
        setComparisonCompetitionIdToTierMap(competitionIdToTierMap);
        setClubName(clubSettings.clubName);
        setClubCountryCode(clubSettings.countryCode ?? 'NO');
      });
    }

  }, [userConfig, clubSettings, competitions]);


  useEffect(() => {
    if (currentUser && userConfig?.club && teams && squads) {
      getPlatformActivityLast14Days(teams, squads, userConfig.club).then((result) => {

        // set platform history
        const history = result.allActivities;
        setPlatformHistory(history);

        // get player overviews for player that may be accessed from the platform history
        const playerIds = result.playerIds;
        const playerIdsToFetch = Array.from(playerIds).filter(id => !playerOverviews[id]);
        if (playerIdsToFetch.length > 0) {
          getPlayerOverviews(playerIdsToFetch, currentUser).then((additionalPlayerOverviews) => {
            setAdditionalPlayerOverviews(additionalPlayerOverviews ?? {});
          });
        }
      });
    }
  }, [teams, squads, userConfig?.club, currentUser]); // eslint-disable-line react-hooks/exhaustive-deps


  const clubColor = getClubColor(userConfig?.club ?? '');


  return (

    <div className='home-view-container'>

      <div className='home-view-inner-container'>
        <div className='home-view-column home-view-left-column'>
          <div className='home-view-history-section-container'>
            <FixedTabLine
              tabOptions={lineTabOptions}
              tabTitles={['xGData', 'averageAgePerMinutePlayed', 'u21MinutesPerMinute']}
              activeTab={activeLineTab}
              setActiveTab={setActiveLineTab}
              withoutTabLine={true}
            />

            <div className='home-view-section-title home-view-history-section-title'>
              {lineData ? translate(lineTabTitles[activeLineTab], userConfig?.language) + ' ' + clubName : ''}
            </div>

            {activeLineTab === 0 && lineData && (
              <div className='home-view-history-section-labels'>
                <div className='home-view-history-section-label'>
                  <div className='home-view-history-section-label-line' style={{ backgroundColor: '#6abe88' }}>&nbsp;</div>
                  <div className='home-view-history-section-label-text'>
                    {translate('xG', userConfig?.language)}
                  </div>
                </div>

                <div className='home-view-history-section-label'>
                  <div className='home-view-history-section-label-line' style={{ backgroundColor: '#c77070' }}>&nbsp;</div>
                  <div className='home-view-history-section-label-text'>
                    {translate('xGC', userConfig?.language)}
                  </div>
                </div>
              </div>
            )}

            {activeLineTab > 0 && lineData && (
              <div className='home-view-history-section-labels home-view-history-section-labels-wide'>
                <div className='home-view-history-section-label'>
                  <div className='home-view-history-section-label-line' style={{ backgroundColor: clubColor }}>&nbsp;</div>
                  <div className='home-view-history-section-label-text'>
                    {clubName}
                  </div>
                </div>

                {Object.entries(comparisonCompetitionIdToTierMap).map(([competitionId, tier]) => {
                  return (
                    <div className='home-view-history-section-label' key={competitionId}>
                      <div className='home-view-history-section-label-line' style={{ backgroundColor: tierColors[tier] }}>&nbsp;</div>
                      <div className='home-view-history-section-label-text'>
                        {competitions[Number(competitionId)].name}
                      </div>
                    </div>
                  );
                })}
              </div>
            )}

            <div className='home-view-history-section-line-plot-container'>
              {lineData && (
                <HomeViewLinePlot
                  data={lineData}
                  activeTab={activeLineTab}
                  clubColor={clubColor}
                  competitionIdToTierMap={comparisonCompetitionIdToTierMap}
                />
              )}
            </div>
          </div>

          <div className='home-view-activity-section-container'>
            <div className='home-view-section-title home-view-activity-section-title'>
              {translate('activityLast14Days', userConfig?.language)}
            </div>

            <div className='home-view-activity-section-log-container'>
              <PlatformHistory
                platformHistory={platformHistory}
                handlePlayerClick={handlePlayerClick}
                handleSquadOrTeamClick={handleSquadOrTeamClick}
              />
            </div>
          </div>
        </div>

        <div className='home-view-column home-view-right-column'>
          <FixedTabLine
            tabOptions={tableOptionsArray}
            activeTab={activeTableTab}
            setActiveTab={setActiveTableTab}
            withoutTabLine={true}
          />
          {tableDataArray.length > 0 && (
            <div className='home-view-table-toggle-section'>
              <div className='home-view-table-toggle-section-text'>
                {translate('advancedData', userConfig?.language)}
              </div>
              <div className='home-view-table-toggle-section-toggle'>
                <Toggle isToggled={isAdvancedTeamDataToggled} setIsToggled={setIsAdvancedTeamDataToggled} boxShadow={'0px 0px 3px 1px #181a23aa'} />
              </div>
            </div>
          )}
          {userConfig && tableDataArray.length > 0 && clubCountryCode && (
            <div className='home-view-table-container'>
              <TeamTable
                data={tableDataArray[activeTableTab]}
                isAdvancedTeamDataToggled={isAdvancedTeamDataToggled}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

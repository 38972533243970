import '../tables.css';

import { StringToAnyMap, UserConfig } from '../../../types';
import { getAge, getDateLocaleFormat, getMonthsLeftOfContract, getDisplayPlayerName } from '../../../utils/playerUtils';
import { countryCodeToCountryInfo } from '../../../static/countries';

import PublicIcon from '@mui/icons-material/Public';
// import HomeIcon from '@mui/icons-material/Home';

import { translate } from '../../../../common/language/translations';
import { LazyImage } from '../../../../common/components/LazyImage';
import { getLocalityIcon, getRoleIcon } from '../../../utils/iconUtils';
import { getDisplayEconomicValue } from '../../../utils/currencyUtils';
import { Toggle } from '../../input/Toggle';
import { getClubLightColor } from '../../../utils/configUtils';
import { updateSeasonStatsToggles } from '../../../services/firestore/userSettings';


const renderInfoCell = (language: string) => {
  const InfoCellRenderer = ({ row }: StringToAnyMap) => {
    return <InfoCell row={row} language={language} />;
  };

  InfoCellRenderer.displayName = 'InfoCellRenderer';
  return InfoCellRenderer;
};

const InfoCell: React.FC<{ row: StringToAnyMap, language: string }> = ({ row, language }) => {

  if (row.original.fullname === 'tableSummary') {
    return (
      <div className='own-team-table-summary-cell' style={{ paddingLeft: 14 }}>
        <div className='own-team-table-summary-cell-label'>
          {translate('summary', language)}
        </div>
        <div className='own-team-table-summary-cell-value'>
          {row.original.numberOfPlayers + ' ' + translate('players', language, true)}
        </div>
      </div>
    );
  }

  return (
    <div className='player-table-info-cell'>

      <LazyImage
        src={row.original.image_url ?? 'https://media.api-sports.io/football/players/0.png'}
        alt=''
        imageClassName='player-table-info-cell-image'
        imageContainerClassName='player-table-info-cell-image-container'
      />

      <div className='own-team-table-info-cell-fullname'>
        {getDisplayPlayerName(row.original.fullname, 25)}
      </div>

    </div>
  );
};


const renderAgeCell = (language: string) => {
  const AgeCellRenderer = ({ row }: StringToAnyMap) => {
    return <AgeCell row={row} language={language} />;
  };

  AgeCellRenderer.displayName = 'AgeCellRenderer';
  return AgeCellRenderer;
};

const AgeCell: React.FC<{ row: StringToAnyMap, language: string }> = ({ row, language }) => {

  if (row.original.fullname === 'tableSummary') {
    return (
      <div className='own-team-table-summary-cell'>
        <div className='own-team-table-summary-cell-label'>
          {translate('average', language)}
        </div>
        <div className='own-team-table-summary-cell-value'>
          {row.original.averageAge ? (row.original.averageAge + ' ' + translate('years', language, true)) : '-'}
        </div>
      </div>
    );
  }

  const birthDate = row.original.birth_date;
  const age = getAge(birthDate);

  return (
    <div className='own-team-table-cell-column'>

      <div>
        {age ? (age + ' ' + translate('years', language, true)) : '-'}
      </div>

      <div style={{ fontSize: 12, color: '#00000099', marginTop: 1 }}>
        {birthDate ? getDateLocaleFormat(birthDate) : ''}
      </div>

    </div>
  );
};


const renderCountryCell = (language: string) => {
  const CountryCellRenderer = ({ row }: StringToAnyMap) => {
    return <CountryCell row={row} language={language} />;
  };

  CountryCellRenderer.displayName = 'CountryCellRenderer';
  return CountryCellRenderer;
};

const CountryCell: React.FC<{ row: StringToAnyMap, language: string }> = ({ row, language }) => {

  if (row.original.fullname === 'tableSummary') {
    const countryCode = row.original.nationalCountryCode;
    const countryInfo = countryCodeToCountryInfo[countryCode];
    const nationalPercentage = row.original.numberOfPlayers ? Math.round((row.original.numberOfNatives / row.original.numberOfPlayers) * 100) : 0;
    return (
      <div className='own-team-table-summary-cell' style={{ height: 48, marginTop: 6 }}>

        <div
          className='own-team-table-cell-row'
          title={translate('numberOfForeigners', language)}
          style={{ fontSize: 12, color: '#000000cc', paddingLeft: 7 }}>
          <PublicIcon style={{ fontSize: 16, color: '#353a48bb', marginBottom: 1 }} />
          <div style={{ marginLeft: 4 }}>
            {row.original.numberOfForeigners}
          </div>
        </div>

        <div className='own-team-table-cell-row' style={{ fontSize: 12, color: '#000000cc' }}>
          {countryInfo && (
            <img
              className='own-team-table-cell-flag'
              style={{ height: 11, marginLeft: 8.5 }}
              src={countryInfo.flagUrl}
              alt=''
              draggable={false}
            />
          )}
          {nationalPercentage + '%'}
        </div>

      </div>
    );
  }

  const countryCode = row.original.country_code;
  const countryInfo = countryCodeToCountryInfo[countryCode];

  return (
    <div className='own-team-table-cell-row' title={countryInfo ? countryInfo.name[language] : ''}>
      {countryInfo && (
        <img
          className='own-team-table-cell-flag'
          src={countryInfo.flagUrl}
          alt=''
          draggable={false}
        />
      )}
      {!countryInfo && (
        <div style={{ marginLeft: 8 }}>
          -
        </div>
      )}
    </div>
  );
};


const renderContractCell = (language: string) => {
  const ContractCellRenderer = ({ row }: StringToAnyMap) => {
    return <ContractCell row={row} language={language} />;
  };

  ContractCellRenderer.displayName = 'ContractCellRenderer';
  return ContractCellRenderer;
};

const ContractCell: React.FC<{ row: StringToAnyMap, language: string }> = ({ row, language }) => {

  if (row.original.fullname === 'tableSummary') {
    return (
      <div className='own-team-table-summary-cell'>
        <div className='own-team-table-summary-cell-label'>
          {translate('average', language)}
        </div>
        <div className='own-team-table-summary-cell-value'>
          {row.original.averageMonthsLeftOfContract ? (row.original.averageMonthsLeftOfContract + ' ' + translate('monthsShort', language)) : '-'}
        </div>
      </div>
    );
  }

  const contractExpiration = row.original.contract_expiration;
  const monthsLeft = getMonthsLeftOfContract(row.original.contract_expiration);

  return (
    <div className='own-team-table-cell-column'>

      <div>
        {monthsLeft !== undefined ? (monthsLeft + ' ' + translate('monthsShort', language)) : '-'}
      </div>

      <div style={{ fontSize: 12, color: '#00000099', marginTop: 1 }}>
        {contractExpiration ? getDateLocaleFormat(contractExpiration) : ''}
      </div>

    </div>
  );
};


const renderTextCell = (property: string, language: string) => {
  const TextCellRenderer = ({ row }: StringToAnyMap) => {
    return <TextCell row={row} property={property} language={language} />;
  };

  TextCellRenderer.displayName = 'TextCellRenderer';
  return TextCellRenderer;
};

const TextCell: React.FC<{ row: StringToAnyMap, property: string, language: string }> = ({
  row,
  property,
  language,
}) => {

  if (row.original.fullname === 'tableSummary') {
    return (
      <div className='own-team-table-summary-cell'>
        <div className='own-team-table-summary-cell-label'>
          {translate('sum', language)}
        </div>
        <div className='own-team-table-summary-cell-value'>
          {row.original[property + 'Total'] ? (getDisplayEconomicValue(row.original[property + 'Total'], true)) : '-'}
        </div>
      </div>
    );
  }

  let value = row.original[property];

  if (value !== undefined && value !== null) {
    value = getDisplayEconomicValue(value, true);
  }

  return (
    <div className='own-team-table-cell-row'>
      {value}
    </div>
  );
};


const renderLocalityCell = (language: string) => {
  const LocalityCellRenderer = ({ row }: StringToAnyMap) => {
    return <LocalityCell row={row} language={language} />;
  };

  LocalityCellRenderer.displayName = 'LocalityCellRenderer';
  return LocalityCellRenderer;
};

const LocalityCell: React.FC<{ row: StringToAnyMap, language: string }> = ({ row, language }) => {

  if (row.original.fullname === 'tableSummary') {

    const localIcon = getLocalityIcon('local', '#353a48bb', 16);
    const nationalIcon = getLocalityIcon('national', '#353a48bb', 16);
    const internationalIcon = getLocalityIcon('international', '#353a48bb', 15);

    return (
      <div className='own-team-table-summary-cell' style={{ height: 44, marginTop: 8 }}>
        <div
          className='own-team-table-cell-row'
          style={{ fontSize: 13, color: '#000000cc', marginLeft: -6 }}
        >
          <div style={{ width: 27, display: 'flex', justifyContent: 'center' }}>
            {localIcon}
          </div>
          <div style={{ width: 27, display: 'flex', justifyContent: 'center' }}>
            {nationalIcon}
          </div>
          <div style={{ width: 27, display: 'flex', justifyContent: 'center' }}>
            {internationalIcon}
          </div>
        </div>
        <div
          className='own-team-table-cell-row'
          style={{ fontSize: 13, color: '#000000cc', marginLeft: -6 }}
        >
          <div style={{ width: 27, display: 'flex', justifyContent: 'center' }}>
            {row.original.numberOfLocalPlayers}
          </div>
          <div style={{ width: 27, display: 'flex', justifyContent: 'center' }}>
            {row.original.numberOfNationalPlayers}
          </div>
          <div style={{ width: 27, display: 'flex', justifyContent: 'center' }}>
            {row.original.numberOfInternationalPlayers}
          </div>
        </div>
      </div>
    );
  }

  const icon = getLocalityIcon(row.original.locality, '#353a48');

  return (
    <div
      className='own-team-table-cell-row'
      style={{ paddingLeft: 5 }}
      title={row.original.locality ? translate(row.original.locality + 'Locality', language, undefined, undefined, true) : undefined}
    >
      {icon}
    </div>
  );
};


// const renderAbcCell = () => {
//   const AbcCellRenderer = ({ row }: StringToAnyMap) => {
//     return <AbcCell row={row} />;
//   };

//   AbcCellRenderer.displayName = 'AbcCellRenderer';
//   return AbcCellRenderer;
// };

// const AbcCell: React.FC<{ row: StringToAnyMap }> = ({ row }) => {

//   if (row.original.fullname === 'tableSummary') {

//     return (
//       <div className='own-team-table-summary-cell' style={{ height: 42, marginTop: 9 }}>
//         <div
//           className='own-team-table-cell-row'
//           style={{ fontSize: 13, color: '#000000cc', marginLeft: -2 }}
//         >
//           <div style={{ width: 27, display: 'flex', justifyContent: 'center' }}>
//             A
//           </div>
//           <div style={{ width: 27, display: 'flex', justifyContent: 'center' }}>
//             B
//           </div>
//           <div style={{ width: 27, display: 'flex', justifyContent: 'center' }}>
//             C
//           </div>
//         </div>
//         <div
//           className='own-team-table-cell-row'
//           style={{ fontSize: 13, color: '#000000cc', marginLeft: -2 }}
//         >
//           <div style={{ width: 27, display: 'flex', justifyContent: 'center' }}>
//             {row.original.numberOfAPlayers}
//           </div>
//           <div style={{ width: 27, display: 'flex', justifyContent: 'center' }}>
//             {row.original.numberOfBPlayers}
//           </div>
//           <div style={{ width: 27, display: 'flex', justifyContent: 'center' }}>
//             {row.original.numberOfCPlayers}
//           </div>
//         </div>
//       </div>
//     );
//   }

//   return (
//     <div
//       className='own-team-table-cell-row'
//       style={{ paddingLeft: 5, fontSize: 14 }}>
//       {row.original.ABC}
//     </div>
//   );
// };


// const renderHomegrownCell = (language: string) => {
//   const HomegrownCellRenderer = ({ row }: StringToAnyMap) => {
//     return <HomegrownCell row={row} language={language} />;
//   };

//   HomegrownCellRenderer.displayName = 'HomegrownCellRenderer';
//   return HomegrownCellRenderer;
// };

// const HomegrownCell: React.FC<{ row: StringToAnyMap, language: string }> = ({ row, language }) => {

//   if (row.original.fullname === 'tableSummary') {

//     const numberOfHomegrownPlayers = row.original.numberOfHomegrownPlayers;
//     const homegrownPercentage = row.original.numberOfPlayers ? Math.round((numberOfHomegrownPlayers / row.original.numberOfPlayers) * 100) : 0;

//     return (
//       <div className='own-team-table-summary-cell' style={{ height: 44, marginTop: 8, marginLeft: 4.5 }}>
//         <div
//           className='own-team-table-cell-row'
//           style={{ fontSize: 12, color: '#000000cc', paddingLeft: 4 }}
//         >
//           <HomeIcon style={{ fontSize: 16, color: '#353a48bb', marginBottom: 1 }} />
//           <div style={{ marginLeft: 4 }}>
//             {numberOfHomegrownPlayers}
//           </div>
//         </div>
//         <div className='own-team-table-cell-row' style={{ fontSize: 12, color: '#000000cc', paddingLeft: 7 }}>
//           {homegrownPercentage + '%'}
//         </div>
//       </div>
//     );
//   }

//   const icon = <HomeIcon style={{ fontSize: 16, color: '#353a48' }} />;

//   return (
//     <div
//       className='own-team-table-cell-row'
//       style={{ paddingLeft: 3 }}
//       title={translate('homegrown', language)}>
//       {row.original.homegrown ? icon : undefined}
//     </div>
//   );
// };


const renderRoleCell = (language: string, clubLightColor: string, isAcademyTable: boolean) => {
  const RoleCellRenderer = ({ row }: StringToAnyMap) => {
    return <RoleCell row={row} language={language} clubLightColor={clubLightColor} isAcademyTable={isAcademyTable} />;
  };

  RoleCellRenderer.displayName = 'RoleCellRenderer';
  return RoleCellRenderer;
};

const RoleCell: React.FC<{
  row: StringToAnyMap,
  language: string,
  clubLightColor: string,
  isAcademyTable: boolean,
}> = ({ row, language, clubLightColor, isAcademyTable }) => {

  if (row.original.fullname === 'tableSummary') {

    if (isAcademyTable) {
      const trialistIcon = getRoleIcon('trialist', '#353a48bb', 16);
      const talentIcon = getRoleIcon('talent', '#353a48bb', 16);
      const potentialIcon = getRoleIcon('potential', '#353a48bb', 15);

      return (
        <div className='own-team-table-summary-cell' style={{ height: 44, marginTop: 8 }}>
          <div
            className='own-team-table-cell-row'
            style={{ fontSize: 13, color: '#000000cc', marginLeft: -6 }}
          >
            <div style={{ width: 27, display: 'flex', justifyContent: 'center' }}>
              {trialistIcon}
            </div>
            <div style={{ width: 27, display: 'flex', justifyContent: 'center' }}>
              {talentIcon}
            </div>
            <div style={{ width: 27, display: 'flex', justifyContent: 'center' }}>
              {potentialIcon}
            </div>
          </div>
          <div
            className='own-team-table-cell-row'
            style={{ fontSize: 13, color: '#000000cc', marginLeft: -6 }}
          >
            <div style={{ width: 27, display: 'flex', justifyContent: 'center' }}>
              {row.original.roleMap.trialist ?? 0}
            </div>
            <div style={{ width: 27, display: 'flex', justifyContent: 'center' }}>
              {row.original.roleMap.talent ?? 0}
            </div>
            <div style={{ width: 27, display: 'flex', justifyContent: 'center' }}>
              {row.original.roleMap.potential ?? 0}
            </div>
          </div>
        </div>
      );
    }

    const loanedInIcon = getRoleIcon('loanedIn', '#353a48bb', 16);
    const loanedOutIcon = getRoleIcon('loanedOut', '#353a48bb', 16);

    return (
      <div className='own-team-table-summary-cell' style={{ height: 50, marginTop: 5, marginLeft: 6 }}>
        <div
          className='own-team-table-cell-row'
          title={translate('numberOfPlayerLoanedIn', language)}
          style={{ fontSize: 12, color: '#000000cc', paddingLeft: 4 }}
        >
          {loanedInIcon}
          <div style={{ marginLeft: 4 }}>
            {row.original.roleMap.loanedIn ?? 0}
          </div>
        </div>
        <div
          className='own-team-table-cell-row'
          title={translate('numberOfPlayerLoanedOut', language)}
          style={{ fontSize: 12, color: '#000000cc', paddingLeft: 4 }}
        >
          {loanedOutIcon}
          <div style={{ marginLeft: 4 }}>
            {row.original.roleMap.loanedOut ?? 0}
          </div>
        </div>
      </div>
    );
  }

  const icon = getRoleIcon(row.original.role, '#353a48', undefined, undefined, clubLightColor);

  return (
    <div
      className='own-team-table-cell-row'
      style={{ paddingLeft: 5 }}
      title={row.original.role ? translate(row.original.role, language) : undefined}
    >
      {icon}
    </div>
  );
};


const getCurrencyExtension = (clubCountryCode: string) => {
  switch (clubCountryCode) {
    case 'NO':
      return 'NOK';
    case 'SE':
      return 'SEK';
    case 'DK':
      return 'DKK';
    case 'JP':
      return '¥';
    default:
      return '€';
  }
};


export const getOwnTeamColumns = (
  tableWidth: number,
  userHasSalaryAccess: boolean,
  userConfig: UserConfig | null,
  clubCountryCode: string,
  showAcademyPlayersInOwnTeamTable: boolean,
) => {

  if (!userConfig) return [];

  const stickyColumnWidth = userHasSalaryAccess ? 215 : 240;
  const remainingWidth = tableWidth - stickyColumnWidth;

  let totalMinWidth = 565;
  if (userHasSalaryAccess) totalMinWidth += 325;

  const unit = remainingWidth / totalMinWidth;

  let toggleSectionPaddingLeft = 8;
  if (userHasSalaryAccess) toggleSectionPaddingLeft += 24;
  if (userHasSalaryAccess && userConfig.language === 'en') toggleSectionPaddingLeft += 2;
  if (!userHasSalaryAccess && userConfig.language === 'en') toggleSectionPaddingLeft += 14;

  let playerColumns = [
    {
      Header:
        <div className='own-team-table-top-level-header-cell-sticky' style={{ width: stickyColumnWidth }}>
          <div
            className='own-team-table-top-level-header-cell-sticky-toggle-section'
            style={{ paddingLeft: toggleSectionPaddingLeft }}
          >
            {translate('showAcademyPlayers', userConfig.language)}
            <div className='season-table-top-level-header-cell-sticky-toggle'>
              <Toggle
                isToggled={showAcademyPlayersInOwnTeamTable}
                setIsToggled={(value: boolean) => updateSeasonStatsToggles(
                  { 'showAcademyPlayersInOwnTeamTable': value },
                  userConfig.email,
                  userConfig.club
                )}
                isSmall={true}
              />
            </div>
          </div>
        </div>,
      accessor: 'fullname',
      sticky: 'left',
      Cell: renderInfoCell(userConfig.language),
      width: stickyColumnWidth,
      isStickyColumn: true,
    },
    {
      Header: translate('age', userConfig.language),
      accessor: 'birth_date',
      Cell: renderAgeCell(userConfig.language),
      minWidth: 90,
      width: 90 * unit,
      needAccess: false,
    },
    {
      Header: translate('contract', userConfig.language),
      accessor: 'contract_expiration',
      Cell: renderContractCell(userConfig.language),
      minWidth: 90,
      width: 90 * unit,
      needAccess: false,
    },
    {
      Header: translate('country', userConfig.language),
      accessor: 'country_code',
      Cell: renderCountryCell(userConfig.language),
      minWidth: 85,
      width: 85 * unit,
      needAccess: false,
    },
    {
      Header: translate('marketValue', userConfig.language),
      accessor: 'market_value',
      Cell: renderTextCell('market_value', userConfig.language),
      minWidth: 120,
      width: 120 * unit,
      title: getCurrencyExtension(clubCountryCode),
      needAccess: false,
    },
  ];

  if (userHasSalaryAccess) {
    playerColumns = playerColumns.concat([
      {
        Header: translate('signingCost', userConfig.language),
        accessor: 'signingCost',
        Cell: renderTextCell('signingCost', userConfig.language),
        minWidth: 120,
        width: 120 * unit,
        title: translate('signingCostLong', userConfig.language) + ' ' + getCurrencyExtension(clubCountryCode),
        needAccess: true,
      },
      {
        Header: translate('salary', userConfig.language),
        accessor: 'salary',
        Cell: renderTextCell('salary', userConfig.language),
        minWidth: 105,
        width: 105 * unit,
        title: translate('salaryCost', userConfig.language) + ' ' + getCurrencyExtension(clubCountryCode),
        needAccess: true,
      },
      {
        Header: translate('bonuses', userConfig.language),
        accessor: 'bonuses',
        Cell: renderTextCell('bonuses', userConfig.language),
        minWidth: 100,
        width: 100 * unit,
        title: translate('bonusesExpected', userConfig.language) + ' ' + getCurrencyExtension(clubCountryCode),
        needAccess: true,
      },
    ]);
  }

  playerColumns = playerColumns.concat([
    {
      Header: translate('locality', userConfig.language),
      accessor: 'locality',
      Cell: renderLocalityCell(userConfig.language),
      minWidth: 90,
      width: 90 * unit,
      needAccess: false,
    },
    {
      Header: translate('teamRole', userConfig.language),
      accessor: 'role',
      Cell: renderRoleCell(userConfig.language, getClubLightColor(userConfig.club), showAcademyPlayersInOwnTeamTable),
      minWidth: 90,
      width: 90 * unit,
      needAccess: false,
    },
  ]);

  return playerColumns;
};

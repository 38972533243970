import fokus_logo from '../../assets/clubLogos/fokus_logo.png';
import glimt_logo from '../../assets/clubLogos/glimt_logo.png';
import molde_logo from '../../assets/clubLogos/molde_logo.png';
import rbk_logo from '../../assets/clubLogos/rbk_logo.png';
import fredrikstad_logo from '../../assets/clubLogos/fredrikstad_logo.png';
import odd_logo from '../../assets/clubLogos/odd_logo.png';
import ranheim_logo from '../../assets/clubLogos/ranheim_logo.png';
import brann_logo from '../../assets/clubLogos/brann_logo.png';
import viking_logo from '../../assets/clubLogos/viking_logo.png';
import lsk_logo from '../../assets/clubLogos/lsk_logo.png';
import vif_logo from '../../assets/clubLogos/vif_logo.png';
import lyn_logo from '../../assets/clubLogos/lyn_logo.png';
import sarpsborg_logo from '../../assets/clubLogos/sarpsborg_logo.png';
import stb_logo from '../../assets/clubLogos/stb_logo.png';
import haugesund_logo from '../../assets/clubLogos/haugesund_logo.png';
import godset_logo from '../../assets/clubLogos/godset_logo.png';
import agf_logo from '../../assets/clubLogos/agf_logo.png';
import goteborg_logo from '../../assets/clubLogos/goteborg_logo.png';
import bryne_logo from '../../assets/clubLogos/bryne_logo.png';
import kfum_logo from '../../assets/clubLogos/kfum_logo.png';
import kristiansund_logo from '../../assets/clubLogos/kristiansund_logo.png';
import hamkam_logo from '../../assets/clubLogos/hamkam_logo.png';
import til_logo from '../../assets/clubLogos/til_logo.png';
import sandefjord_logo from '../../assets/clubLogos/sandefjord_logo.png';
import kongsvinger_logo from '../../assets/clubLogos/kongsvinger_logo.png';
import sogndal_logo from '../../assets/clubLogos/sogndal_logo.png';
import egersund_logo from '../../assets/clubLogos/egersund_logo.png';
import start_logo from '../../assets/clubLogos/start_logo.png';
import hammarby_logo from '../../assets/clubLogos/hammarby_logo.png';
import aik_logo from '../../assets/clubLogos/aik_logo.png';
import hacken_logo from '../../assets/clubLogos/hacken_logo.png';
import ntf_logo from '../../assets/clubLogos/ntf_logo.png';
// import urawa_logo from '../../assets/clubLogos/urawa_logo.png';


import { StringToAnyMap } from '../types';


// logoFormat: 'square' | 'wide' | 'wider' | 'widest' | 'tall' | 'taller' | 'veryTall' | 'tallest'
// logoShadow: 'center' | 'centerSmall' | 'left' | 'leftSmall' | 'topSmall' | 'tall'

export const clubConfigs: StringToAnyMap = {


  // organizations

  'fokus': {
    'color': '#ffdb65',
    'secondaryColor': '#ffdb65',
    'lightColor': '#ffdf76',
    'contrastColor': '#2bffbf',

    'logo': fokus_logo,
    'logoFormat': 'square',
    'logoShadow': 'center',
  },

  'demo': {
    'color': '#ffde00',
    'secondaryColor': '#ffde00',
    'lightColor': '#ffe432',
    'contrastColor': '#2bffbf',

    'logo': glimt_logo,
    'logoFormat': 'square',
    'logoShadow': 'center',
  },

  'norskfotball': {
    'color': '#fcca70',
    'secondaryColor': '#fcca70',
    'lightColor': '#ffd27e',
    'contrastColor': '#2bffbf',

    'logo': ntf_logo,
    'logoFormat': 'square',
    'logoShadow': 'center',
  },


  // norwegian clubs

  'glimt': {
    'color': '#ffde00',
    'secondaryColor': '#ffde00',
    'lightColor': '#ffe432',
    'contrastColor': '#2bffbf',

    'logo': glimt_logo,
    'logoFormat': 'square',
    'logoShadow': 'center',
  },

  'molde': {
    'color': '#21a3ff',
    'secondaryColor': '#21a3ff',
    'lightColor': '#94d2ff',
    'contrastColor': '#2bffbf',

    'logo': molde_logo,
    'logoFormat': 'wider',
    'logoShadow': 'center',
  },

  'rbk': {
    'color': '#43f291',
    'secondaryColor': '#43f291',
    'lightColor': '#43f291',
    'contrastColor': '#fcca70',

    'logo': rbk_logo,
    'logoFormat': 'widest',
    'logoShadow': 'centerSmall',
  },

  'brann': {
    'color': '#ff2833',
    'secondaryColor': '#ff4650',
    'lightColor': '#ff787f',
    'contrastColor': '#2bffbf',

    'logo': brann_logo,
    'logoFormat': 'widest',
    'logoShadow': 'center',
  },

  'viking': {
    'color': '#e8cb6c',
    'secondaryColor': '#e8cb6c',
    'lightColor': '#ffdb66',
    'contrastColor': '#2bffbf',

    'logo': viking_logo,
    'logoFormat': 'wider',
    'logoShadow': 'left',
  },

  'lsk': {
    'color': '#fddc02',
    'secondaryColor': '#fddc02',
    'lightColor': '#ffe853',
    'contrastColor': '#2bffbf',

    'logo': lsk_logo,
    'logoFormat': 'square',
    'logoShadow': 'center',
  },

  'godset': {
    'color': '#008deb',
    'secondaryColor': '#ffd12a',
    'lightColor': '#ffd12a',
    'contrastColor': '#2bffbf',

    'logo': godset_logo,
    'logoFormat': 'square',
    'logoShadow': 'center',
  },

  'sarpsborg': {
    'color': '#3e72ff',
    'secondaryColor': '#7096ff',
    'lightColor': '#a8bfff',
    'contrastColor': '#2bffbf',

    'logo': sarpsborg_logo,
    'logoFormat': 'square',
    'logoShadow': 'tall',
  },

  'haugesund': {
    'color': '#44a4ff',
    'secondaryColor': '#44a4ff',
    'lightColor': '#87c5ff',
    'contrastColor': '#2bffbf',

    'logo': haugesund_logo,
    'logoFormat': 'square',
    'logoShadow': 'topSmall',
  },

  'odd': {
    'color': '#ffffff',
    'secondaryColor': '#ffde00',
    'lightColor': '#ffe432',
    'contrastColor': '#2bffbf',

    'logo': odd_logo,
    'logoFormat': 'square',
    'logoShadow': 'center',
  },

  'fredrikstad': {
    'color': '#ff5f67',
    'secondaryColor': '#ff5f67',
    'lightColor': '#ff6971',
    'contrastColor': '#2bffbf',

    'logo': fredrikstad_logo,
    'logoFormat': 'wider',
    'logoShadow': 'leftSmall',
  },

  'kfum': {
    'color': '#ff3f46',
    'secondaryColor': '#ff3f46',
    'lightColor': '#ff6469',
    'contrastColor': '#2bffbf',

    'logo': kfum_logo,
    'logoFormat': 'square',
    'logoShadow': 'center',
  },

  'kbk': {
    'color': '#0073ff',
    'secondaryColor': '#fcca70',
    'lightColor': '#ffd78d',
    'contrastColor': '#2bffbf',

    'logo': kristiansund_logo,
    'logoFormat': 'square',
    'logoShadow': 'center',
  },

  'hamkam': {
    'color': '#21c859',
    'secondaryColor': '#21c859',
    'lightColor': '#1fd15a',
    'contrastColor': '#fcca70',

    'logo': hamkam_logo,
    'logoFormat': 'square',
    'logoShadow': 'center',
  },

  'til': {
    'color': '#ff0915',
    'secondaryColor': '#d9bd6f',
    'lightColor': '#ff6971',
    'contrastColor': '#2bffbf',

    'logo': til_logo,
    'logoFormat': 'square',
    'logoShadow': 'center',
  },

  'sandefjord': {
    'color': '#0080e9',
    'secondaryColor': '#ff304c',
    'lightColor': '#52b1ff',
    'contrastColor': '#2bffbf',

    'logo': sandefjord_logo,
    'logoFormat': 'tall',
    'logoShadow': 'center',
  },

  'stb': {
    'color': '#1184ff',
    'secondaryColor': '#ffc342',
    'lightColor': '#ffcd62',
    'contrastColor': '#2bffbf',

    'logo': stb_logo,
    'logoFormat': 'square',
    'logoShadow': 'tall',
  },

  'ranheim': {
    'color': '#4c79ff',
    'secondaryColor': '#7a9bff',
    'lightColor': '#b1c5ff',
    'contrastColor': '#2bffbf',

    'logo': ranheim_logo,
    'logoFormat': 'widest',
    'logoShadow': 'center',
  },

  'vif': {
    'color': '#0e7eff',
    'secondaryColor': '#4fa1ff',
    'lightColor': '#ff7d82',
    'contrastColor': '#2bffbf',

    'logo': vif_logo,
    'logoFormat': 'wider',
    'logoShadow': 'center',
  },

  'lyn': {
    'color': '#ff2f39',
    'secondaryColor': '#ffdb3b',
    'lightColor': '#ffe572',
    'contrastColor': '#2bffbf',

    'logo': lyn_logo,
    'logoFormat': 'square',
    'logoShadow': 'center',
  },

  'kongsvinger': {
    'color': '#ff282f',
    'secondaryColor': '#3fafff',
    'lightColor': '#ff686d',
    'contrastColor': '#2bffbf',

    'logo': kongsvinger_logo,
    'logoFormat': 'square',
    'logoShadow': 'center',
  },

  'bryne': {
    'color': '#ff332c',
    'secondaryColor': '#3bcf4f',
    'lightColor': '#ff7c78',
    'contrastColor': '#2bffbf',

    'logo': bryne_logo,
    'logoFormat': 'square',
    'logoShadow': 'center',
  },

  'sogndal': {
    'color': '#ffd332',
    'secondaryColor': '#ffd332',
    'lightColor': '#ffdf69',
    'contrastColor': '#2bffbf',

    'logo': sogndal_logo,
    'logoFormat': 'wide',
    'logoShadow': 'center',
  },

  'egersund': {
    'color': '#ffe015',
    'secondaryColor': '#ffe015',
    'lightColor': '#ffe53f',
    'contrastColor': '#2bffbf',

    'logo': egersund_logo,
    'logoFormat': 'tallest',
    'logoShadow': 'center',
  },

  'start': {
    'color': '#ffdc16',
    'secondaryColor': '#ffdc16',
    'lightColor': '#ffe554',
    'contrastColor': '#2bffbf',

    'logo': start_logo,
    'logoFormat': 'wide',
  },


  // swedish clubs

  'hammarby': {
    'color': '#00bc64',
    'secondaryColor': '#ffd52c',
    'lightColor': '#00e77b',
    'contrastColor': '#ffde59',

    'logo': hammarby_logo,
    'logoFormat': 'square',
  },

  'goteborg': {
    'color': '#2e7bff',
    'secondaryColor': '#ffcd29',
    'lightColor': '#ffda61',
    'contrastColor': '#2bffbf',

    'logo': goteborg_logo,
    'logoFormat': 'tallest',
    'logoShadow': 'center',
  },

  'aik': {
    'color': '#ffee00',
    'secondaryColor': '#ffee00',
    'lightColor': '#fff454',
    'contrastColor': '#2bffbf',

    'logo': aik_logo,
    'logoFormat': 'taller',
    'logoShadow': 'centerSmall',
  },

  'hacken': {
    'color': '#f7ef02',
    'secondaryColor': '#f7ef02',
    'lightColor': '#fff94e',
    'contrastColor': '#2bffbf',

    'logo': hacken_logo,
    'logoFormat': 'veryTall',
    'logoShadow': 'center',
  },


  // danish clubs

  'agf': {
    'color': '#ffffff',
    'secondaryColor': '#ff6964',
    'lightColor': '#ffffff',
    'contrastColor': '#2bffbf',

    'logo': agf_logo,
    'logoFormat': 'taller',
    'logoShadow': 'tall',
  },


  // former clubs

  // 'urawa': {
  //   'color': '#ff2d53',
  //   'secondaryColor': '#ff4365',
  //   'lightColor': '#ff5e7c',
  //   'contrastColor': '#2bffbf',

  //   'logo': urawa_logo,
  //   'logoFormat': 'taller',
  //   'logoShadow': 'center',
  // },
};

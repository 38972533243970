import './../modals.css';

import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../../recoil/atoms/userConfigState';
import { allUserConfigsState } from '../../../recoil/atoms/allUserConfigsState';

import CloseIcon from '@mui/icons-material/Close';
import PlagiarismIcon from '@mui/icons-material/Plagiarism';
import DescriptionIcon from '@mui/icons-material/Description';
import MessageIcon from '@mui/icons-material/Message';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import CheckIcon from '@mui/icons-material/Check';

import { translate } from '../../../../common/language/translations';
import { PlayerReport } from '../../../types';
import { ReportAction } from '../../documents/report/reportReducer';
import { getDateLocaleFormat } from '../../../utils/playerUtils';
import { getClubColor } from '../../../utils/configUtils';


interface ReportEditHistoryModalProps {
  dispatch: React.Dispatch<ReportAction>;
  existingReportSelected: PlayerReport;
  activePreviousEditIndex: number | undefined;
}

export const ReportEditHistoryModal: React.FC<ReportEditHistoryModalProps> = ({
  dispatch,
  existingReportSelected,
  activePreviousEditIndex,
}) => {

  const userConfig = useRecoilValue(userConfigState);
  const allUsersWithSameClub = useRecoilValue(allUserConfigsState);


  const allDates: string[] = [
    existingReportSelected.date.split('T')[0],
    ...(existingReportSelected.previousEdits ?? []).map(edit => edit.dateOverwritten.split('T')[0]),
  ];

  const user = existingReportSelected.userEmail === userConfig?.email
    ? translate('you', userConfig?.language)
    : allUsersWithSameClub[existingReportSelected.userEmail].name;

  const theReport = translate('the' + existingReportSelected.reportType, userConfig?.language);


  const handleVersionClick = (index: number) => {
    let newIndex: number | undefined = index;
    if (index === allDates.length - 1) {
      newIndex = undefined;
    }

    let reportFields = existingReportSelected.reportFields;
    if (newIndex !== undefined && existingReportSelected.previousEdits) {
      reportFields = existingReportSelected.previousEdits[newIndex].reportFields;
    }

    dispatch({ type: 'SET_ACTIVE_PREVIOUS_EDIT_INDEX', payload: { index: newIndex, reportFields: reportFields } });

    setTimeout(() => {
      dispatch({ type: 'SET_IS_EDIT_HISTORY_MODAL_OPEN', payload: false });
    }, 200);
  };


  const clubColor = getClubColor(userConfig?.club ?? '');


  return (
    <div className='modal-root-container report-edit-history-modal-root'>

      <CloseIcon
        className='modal-icon modal-exit-icon'
        style={{ fontSize: 24 }}
        onClick={() => dispatch({ type: 'SET_IS_EDIT_HISTORY_MODAL_OPEN', payload: false })}
      />

      <div className='modal-root-title'>
        {translate('editHistory', userConfig?.language)}
      </div>

      <div className='report-edit-history-modal-content-section'>

        {allDates.map((date, index) => {

          const verb = translate(index === 0 ? 'created' : 'edited', userConfig?.language);

          const text = user + ' ' + verb + ' ' + theReport;

          const isViewedEdit = activePreviousEditIndex === undefined
            ? index === allDates.length - 1
            : index === activePreviousEditIndex;

          return (
            <div
              key={index}
              className='report-edit-history-modal-edit-entry'
            >

              <div className='report-edit-history-modal-date-divider'>
                <div className='report-edit-history-modal-date'>
                  {getDateLocaleFormat(date.split('T')[0])}
                </div>
              </div>

              <div
                className={'report-edit-history-modal-edit-entry-text' + (isViewedEdit ? ' report-edit-history-modal-edit-entry-text-viewed' : '')}
                title={!isViewedEdit ? translate('showVersion', userConfig?.language) : undefined}
                onClick={() => !isViewedEdit ? handleVersionClick(index) : null}
              >
                {isViewedEdit && (
                  <div className='report-edit-history-modal-edit-entry-view-icon'>
                    <CheckIcon style={{ fontSize: 18, color: clubColor }} />
                  </div>
                )}

                {text}

                {existingReportSelected.reportType === 'ScoutingReport' && <PlagiarismIcon style={{ fontSize: 20, color: '#ffffffcc' }} />}
                {existingReportSelected.reportType === 'MatchReport' && <DescriptionIcon style={{ fontSize: 20, color: '#ffffffcc' }} />}
                {existingReportSelected.reportType === 'Comment' && <MessageIcon style={{ fontSize: 19, marginTop: 1, color: '#ffffffaa' }} />}
                {existingReportSelected.reportType === 'Attachment' && <PictureAsPdfIcon style={{ fontSize: 19, marginTop: 1, color: '#ffffffcc' }} />}
              </div>

            </div>
          );
        })}


      </div>

    </div>
  );
};

import { ReactNode } from 'react';
import { CellProps } from 'react-table';
import { StringToAnyMap } from '../../../types';
import { translate } from '../../../../common/language/translations';

import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import RocketIcon from '@mui/icons-material/Rocket';


const TeamCell: React.FC<CellProps<StringToAnyMap, StringToAnyMap>> = ({ row }) => {

  let clubName = row.original.club_name;
  const clubNameArray = clubName.split(' ');

  if (clubNameArray.length > 2 && clubName.length > 25) {
    clubName = clubNameArray[0] + ' ' + clubNameArray[clubNameArray.length - 1];
  }

  return (
    <div className='team-table-club-cell-row'>
      {row.original.club_logo_url && (
        <img
          className='team-table-club-logo'
          src={row.original.club_logo_url}
          alt=''
          draggable={false}
        />
      )}
      {clubName}
    </div>
  );
};


const TeamTextCell: React.FC<CellProps<StringToAnyMap, ReactNode>> = ({ value }) => {
  return <div className='team-table-text-cell'>{value}</div>;
};


const TeamTextBoldCell: React.FC<CellProps<StringToAnyMap, ReactNode>> = ({ value }) => {
  return <div className='team-table-text-cell-bold'>{value}</div>;
};


export const getTeamColumns = (
  tableWidth: number,
  isAdvancedTeamDataToggled: boolean,
  language: string,
) => {

  const remainingColumnsWidth = tableWidth - 150;

  if (isAdvancedTeamDataToggled) {
    return [
      {
        Header: translate('team', language),
        accessor: 'id',
        sticky: 'left',
        Cell: TeamCell,
        width: 150,
      },
      {
        Header: translate('matchesPlayedShort', language),
        title: translate('matchesPlayed', language),
        accessor: 'matchesPlayedAdvanced',
        Cell: TeamTextCell,
        width: remainingColumnsWidth * 0.15,
      },
      {
        Header: 'xG +',
        title: translate('xGPer90', language),
        accessor: 'xG',
        Cell: TeamTextCell,
        width: remainingColumnsWidth * 0.17,
        sortType: (rowA: StringToAnyMap, rowB: StringToAnyMap) => {
          return rowA.original.xG - rowB.original.xG;
        }
      },
      {
        Header: 'xG -',
        title: translate('xGCPer90', language),
        accessor: 'xGC',
        Cell: TeamTextCell,
        width: remainingColumnsWidth * 0.17,
        sortType: (rowA: StringToAnyMap, rowB: StringToAnyMap) => {
          return rowA.original.xGC - rowB.original.xGC;
        }
      },
      {
        Header: 'xG +/-',
        title: translate('xGDifferencePer90', language),
        accessor: 'xGDifference',
        Cell: TeamTextCell,
        width: remainingColumnsWidth * 0.17,
        sortType: (rowA: StringToAnyMap, rowB: StringToAnyMap) => {
          return rowA.original.xGDifference - rowB.original.xGDifference;
        }
      },
      {
        Header: () => <CalendarMonthIcon style={{ fontSize: 20, marginTop: -2 }} />,
        title: translate('averageAgePerMinutePlayed', language),
        accessor: 'averageAgePerMinutePlayed',
        Cell: TeamTextCell,
        width: remainingColumnsWidth * 0.17,
        icon: true,
      },
      {
        Header: () => <RocketIcon style={{ fontSize: 20, marginTop: -2 }} />,
        title: translate('u21MinutesPerMinute', language),
        accessor: 'u21MinutesPerMinute',
        Cell: TeamTextCell,
        width: remainingColumnsWidth * 0.17,
        icon: true,
      },
    ];
  }

  return [
    {
      Header: translate('team', language),
      accessor: 'id',
      sticky: 'left',
      Cell: TeamCell,
      width: 150,
    },
    {
      Header: translate('matchesPlayedShort', language),
      title: translate('matchesPlayed', language),
      accessor: 'matchesPlayed',
      Cell: TeamTextCell,
      width: remainingColumnsWidth * 0.125,
    },
    {
      Header: translate('matchesWonShort', language),
      title: translate('matchesWon', language),
      accessor: 'matchesWon',
      Cell: TeamTextCell,
      width: remainingColumnsWidth * 0.125,
    },
    {
      Header: translate('matchesDrawnShort', language),
      title: translate('matchesDrawn', language),
      accessor: 'matchesDrawn',
      Cell: TeamTextCell,
      width: remainingColumnsWidth * 0.125,
    },
    {
      Header: translate('matchesLostShort', language),
      title: translate('matchesLost', language),
      accessor: 'matchesLost',
      Cell: TeamTextCell,
      width: remainingColumnsWidth * 0.125,
    },
    {
      Header: '+',
      title: translate('goalsScored', language),
      accessor: 'goalsScored',
      Cell: TeamTextCell,
      width: remainingColumnsWidth * 0.125,
    },
    {
      Header: '-',
      title: translate('goalsConceded', language),
      accessor: 'goalsConceded',
      Cell: TeamTextCell,
      width: remainingColumnsWidth * 0.125,
    },
    {
      Header: '+/-',
      title: translate('goalDifference', language),
      accessor: 'goalDifference',
      Cell: TeamTextCell,
      width: remainingColumnsWidth * 0.13,
      sortType: (rowA: StringToAnyMap, rowB: StringToAnyMap) => {
        return rowA.original.goalDifference - rowB.original.goalDifference;
      }
    },
    {
      Header: translate('pointsShort', language),
      title: translate('points', language),
      accessor: 'points',
      Cell: TeamTextBoldCell,
      width: remainingColumnsWidth * 0.12,
    },
  ];
};

import './../documents.css';

import { useEffect, useReducer, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../../recoil/atoms/userConfigState';
import { allUserConfigsState } from '../../../recoil/atoms/allUserConfigsState';
import { useSetAndTrackSelectedPlayerState } from '../../../recoil/hooks/useSetAndTrackSelectedPlayerState';
import { useOpenGlobalModal } from '../../../recoil/hooks/useOpenGlobalModal';
import { useTrackEvent } from '../../../services/server/analytics/useTrackEvent';

import reportReducer from './reportReducer';
import initializeState from './reportInitialState';

import CloseIcon from '@mui/icons-material/Close';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import CheckIcon from '@mui/icons-material/Check';
import EditNoteIcon from '@mui/icons-material/EditNote';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import RestoreIcon from '@mui/icons-material/Restore';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import { Dialog } from '@mui/material';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { enGB, nb } from 'date-fns/locale';

import {
  StringToStringMap,
  StringToNumberMap,
  StringToBooleanMap,
  StringToAnyMap,
  PlayerOverview,
  PlayerReport,
  PlayerReportEdit,
  PlayerEntry,
} from '../../../types';

import { translate } from '../../../../common/language/translations';
import { PlayerSimpleTable } from '../../tables/playerSimpleTable/PlayerSimpleTable';
import { getDateLocaleFormat, getStrictPlayerId } from '../../../utils/playerUtils';
import { FileUpload } from '../../input/FileUpload';
import { getShortenedFileName } from '../../modals/globalModals/PdfModal';
import { ReportScores } from './ReportScores';
import { addReport, updateReport, updateReportFileNamesAndFileUrls, updateReportIsDeletedStatus } from '../../../services/firestore/reports';
import { getLatestReportVersion } from './reportVersions';
import { uploadFiles } from '../../../services/storage/files';
import { generateRandomId } from '../../../utils/utils';
import { ReportEditHistoryModal } from '../../modals/historyModals/ReportEditHistoryModal';
import { getClubColor } from '../../../utils/configUtils';


interface ReportProps {
  newReportTypeSelected: string | undefined;
  existingReportSelected: PlayerReport | undefined;

  reportHasUnsavedChanges: boolean;
  setReportHasUnsavedChanges: (value: boolean) => void;

  handleCloseIconClick: () => void;
  addOrUpdateReport: (report: PlayerReport, isNewReport: boolean) => void;

  playerOverview: PlayerOverview | undefined;
  playerEntry?: PlayerEntry; // provided for players without data

  averageMatchReportScores?: StringToNumberMap;

  isArchiveView?: boolean;
  isLoadingSelectedPlayerOverview?: boolean;
}


export const Report: React.FC<ReportProps> = ({
  newReportTypeSelected,
  existingReportSelected,

  reportHasUnsavedChanges,
  setReportHasUnsavedChanges,

  handleCloseIconClick,
  addOrUpdateReport,

  playerOverview,
  playerEntry,

  averageMatchReportScores,

  isArchiveView,
  isLoadingSelectedPlayerOverview,
}) => {

  const userConfig = useRecoilValue(userConfigState);
  const allUsersWithSameClub = useRecoilValue(allUserConfigsState);
  const setAndTrackSelectedPlayerState = useSetAndTrackSelectedPlayerState();

  const { openTextModal, openPdfModal } = useOpenGlobalModal();
  const trackEvent = useTrackEvent();

  const [isLoading, setIsLoading] = useState(false);
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);


  const [state, dispatch] = useReducer(
    reportReducer,
    undefined,
    () => initializeState(newReportTypeSelected, existingReportSelected, userConfig?.club, playerOverview?.club?.name)
  );


  const dateFormat = 'dd.MM.yyyy';
  const locale = userConfig && userConfig.language === 'no'
    ? nb
    : enGB;


  const playerData = playerOverview ?? playerEntry;
  const playerTableData = isLoadingSelectedPlayerOverview
    ? undefined
    : (playerData ?? {
      id: existingReportSelected?.playerId,
      fullname: existingReportSelected?.playerName,
    });

  const onPlayerClick = (player: StringToAnyMap) => {
    setAndTrackSelectedPlayerState({
      id: player.id,
      fullname: player.fullname,
      playerOverview: playerOverview
    });
  };


  const isValidReport = () => {
    if (state.reportType === 'Comment' && state.comment.trim().length === 0) {
      dispatch({ type: 'SET_COMMENT', payload: '' });
      return false;
    }

    if (state.reportType === 'Attachment' && state.fileNames.length === 0) {
      return false;
    }

    const newRequiredFieldsToShow: StringToBooleanMap = {};

    if (state.reportType === 'ScoutingReport') {
      Object.entries(state.scores).forEach(([scoreKey, scoreValue]) => {
        if (!scoreValue) {
          newRequiredFieldsToShow[scoreKey + 'ShowRequired'] = true;
        }
      });
    }

    if (state.reportType === 'MatchReport') {
      if (!state.scores.overallScore) {
        newRequiredFieldsToShow['overallScoreShowRequired'] = true;
      }
      if (state.matchReportInfo?.team.trim().length === 0) {
        newRequiredFieldsToShow['teamShowRequired'] = true;
      }
      if (state.matchReportInfo?.opponent.trim().length === 0) {
        newRequiredFieldsToShow['opponentShowRequired'] = true;
      }
      if (!state.matchReportInfo?.matchDate) {
        newRequiredFieldsToShow['matchDateShowRequired'] = true;
      }
    }

    if (Object.keys(newRequiredFieldsToShow).length > 0) {
      dispatch({ type: 'SET_REQUIRED_FIELDS_TO_SHOW', payload: newRequiredFieldsToShow });
      return false;
    }

    return true;
  };


  const handleUpdateReport = async () => {
    if (!userConfig || !existingReportSelected) return;

    // first we check if there are any edits to the report
    const currentReportFields: StringToAnyMap = { ...existingReportSelected.reportFields };
    let newReportFields: StringToAnyMap = {
      'comment': state.comment,
    };

    if (state.reportType === 'ScoutingReport' || state.reportType === 'MatchReport') {
      newReportFields = {
        ...newReportFields,
        ...state.scores,
      };

      if (state.reportType === 'MatchReport') {
        newReportFields = {
          ...newReportFields,
          ...state.matchReportInfo,
        };
      }
    }

    let hasChanges = false;
    for (const key in newReportFields) {
      if (newReportFields[key] !== currentReportFields[key]) {
        hasChanges = true;
        break;
      }
    }

    // files are handled separately - we must be careful to not overwrite any files in storage
    const uploadedFiles = state.additionalReportState.uploadedFiles;
    const newFilesHaveBeenUploaded = uploadedFiles && uploadedFiles.length > 0;
    const existingFilesHaveBeenRemoved = state.fileNames.length !== (currentReportFields.fileNames ?? []).length;
    const hasFileChanges = newFilesHaveBeenUploaded || existingFilesHaveBeenRemoved;

    if (!hasChanges && !hasFileChanges) {
      dispatch({ type: 'SET_IS_EDIT_MODE', payload: false });
      setReportHasUnsavedChanges(false);
      return;
    }

    if (newFilesHaveBeenUploaded) {
      setIsLoading(true);

      // if any file upload would cause a file to be overwritten, we append a random identifier to the file name
      // we need to check the file names for all edits of the report
      const fileNamesInStorage: string[] = [];
      if (currentReportFields.fileNames) {
        fileNamesInStorage.push(...currentReportFields.fileNames);
      }
      if (existingReportSelected.previousEdits) {
        existingReportSelected.previousEdits.forEach(edit => {
          if (edit.reportFields.fileNames) {
            fileNamesInStorage.push(...edit.reportFields.fileNames);
          }
        });
      }

      const filesToUpload = uploadedFiles.map(file => {
        if (fileNamesInStorage.includes(file.name)) {
          const versionedFileName = file.name + '_' + generateRandomId(16);
          return new File([file], versionedFileName);
        }
        return file;
      });

      const newFileUrls = await uploadFiles(filesToUpload, 'reports', existingReportSelected.id as string, userConfig.club);
      const fileNameToNewUrl: StringToStringMap = {};
      newFileUrls.forEach((url, index) => {
        fileNameToNewUrl[uploadedFiles[index].name] = url;
      });

      // the url to storage will be the one from fileNameToNewUrl if the file was added now, otherwise it will be the url in fileUrls at the same index
      const newFileUrlsToSet = state.fileNames.map(
        fileName => fileNameToNewUrl[fileName] ?? state.fileUrls[state.fileNames.indexOf(fileName)]
      );

      if (newFileUrlsToSet && newFileUrlsToSet.length > 0) {
        newReportFields.fileNames = state.fileNames;
        newReportFields.fileUrls = newFileUrlsToSet;
      }
    }
    else {
      newReportFields.fileNames = state.fileNames;
      newReportFields.fileUrls = state.fileUrls;
    }

    // we always shift the dates such that the report date reflects the date the report was added, and every edit date reflects the date of the next edit
    const newEditEntry: PlayerReportEdit = { dateOverwritten: new Date().toISOString(), reportFields: currentReportFields };
    const newPreviousEdits: PlayerReportEdit[] = existingReportSelected.previousEdits
      ? [...existingReportSelected.previousEdits, newEditEntry]
      : [newEditEntry];

    trackEvent('ReportEvent', { action: 'edited', reportType: existingReportSelected.reportType });
    await updateReport(
      existingReportSelected.id as string,
      newReportFields,
      newPreviousEdits,
      userConfig.club
    );

    const updatedReport: PlayerReport = {
      ...existingReportSelected,
      reportFields: newReportFields,
      previousEdits: newPreviousEdits,
    };
    addOrUpdateReport(updatedReport, false);

    setIsLoading(false);
  };


  const handleAddReport = async () => {
    if (!userConfig || !newReportTypeSelected || !playerData) return;

    const report: PlayerReport = {
      playerId: getStrictPlayerId(playerData.id),
      playerName: playerData.fullname,
      userEmail: userConfig.email,
      date: new Date().toISOString(),
      isDeleted: false,
      reportType: newReportTypeSelected,
      reportVersion: getLatestReportVersion(userConfig.club),
      reportFields: {
        'comment': state.comment,
      },
    };

    if (newReportTypeSelected === 'ScoutingReport' || newReportTypeSelected === 'MatchReport') {
      report.reportFields = {
        ...report.reportFields,
        ...state.scores,
      };

      if (newReportTypeSelected === 'MatchReport') {
        report.reportFields = {
          ...report.reportFields,
          ...state.matchReportInfo,
        };
      }
    }

    // (1) add report to firestore and get id
    trackEvent('ReportEvent', { action: 'added', reportType: newReportTypeSelected });
    const reportId = await addReport(report, userConfig.club);

    if (reportId) {
      const newReport: PlayerReport = { ...report, id: reportId };

      // if attachments are uploaded, we do two additional steps:
      // (2) upload files to storage and get urls, (3) update report with urls both in firestore and in state
      if (state.additionalReportState.uploadedFiles) {
        setIsLoading(true);

        const newFileUrls = await uploadFiles(state.additionalReportState.uploadedFiles, 'reports', reportId, userConfig.club);

        if (newFileUrls && newFileUrls.length > 0) {
          const success = await updateReportFileNamesAndFileUrls(reportId, state.fileNames, newFileUrls, userConfig.club);

          if (success) {
            newReport.reportFields.fileNames = state.fileNames;
            newReport.reportFields.fileUrls = newFileUrls;
          }
        }

        setIsLoading(false);
      }

      addOrUpdateReport(newReport, true);
    }
  };


  const handleSubmitReport = async () => {
    setSubmitButtonDisabled(true);

    if (isValidReport()) {
      if (state.additionalReportState.isEditMode) {
        await handleUpdateReport();
      }
      else {
        await handleAddReport();
      }
    }

    setSubmitButtonDisabled(false);
  };


  const handleDeleteOrRestoreReport = async () => {
    if (!existingReportSelected || !userConfig) return;

    setSubmitButtonDisabled(true);

    const newIsDeletedStatus = !existingReportSelected.isDeleted;
    trackEvent('ReportEvent', { action: newIsDeletedStatus ? 'deleted' : 'restored', reportType: existingReportSelected.reportType });
    const success = await updateReportIsDeletedStatus(existingReportSelected.id as string, newIsDeletedStatus, userConfig.club);

    if (success) {
      addOrUpdateReport({ ...existingReportSelected, isDeleted: newIsDeletedStatus }, false);
    }

    setSubmitButtonDisabled(false);
  };


  const handleUploadFile = (file: File) => {
    const newFileName = file.name;
    if (state.fileNames.includes(newFileName)) {
      openTextModal(
        translate('errorOccurred', userConfig?.language),
        translate('fileAlreadyUploaded', userConfig?.language),
      );
      return;
    }

    dispatch({ type: 'UPLOAD_FILE', payload: file });
  };


  const handleRemoveFile = (event: React.MouseEvent, fileUrl: string, fileName: string) => {
    event.stopPropagation();
    dispatch({ type: 'REMOVE_FILE', payload: { fileUrl, fileName } });
  };


  useEffect(() => {
    // revoke the object url to avoid memory leaks
    return () => {
      state.fileUrls.forEach((url) => URL.revokeObjectURL(url));
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    // if report already has unsaved changes, we don't bother to recheck if the report ends up back in the same state
    if (!reportHasUnsavedChanges) {

      // if this is a new report, the report has unsaved changes if any data is entered
      if (!existingReportSelected) {
        if (
          Object.values(state.scores).some(score => score > 0) ||
          state.comment.trim().length > 0 ||
          (state.matchReportInfo && state.matchReportInfo.team !== (playerOverview?.club?.name ?? '')) ||
          (state.matchReportInfo && state.matchReportInfo.opponent.length > 0) ||
          (state.matchReportInfo && state.matchReportInfo.matchDate !== new Date().toISOString().split('T')[0]) ||
          state.fileNames.length > 0
        ) {
          setReportHasUnsavedChanges(true);
        }
      }

      // if report is being edited, we just assume unsaved changes and don't bother to check if the current state actually has unsaved changes
      else if (state.additionalReportState.isEditMode) {
        setReportHasUnsavedChanges(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    state,
    existingReportSelected,
    reportHasUnsavedChanges,
  ]);


  useEffect(() => {
    setIsLoading(false);
    setReportHasUnsavedChanges(false);

    dispatch({
      type: 'INITIALIZE_STATE',
      payload: initializeState(newReportTypeSelected, existingReportSelected, userConfig?.club, playerOverview?.club?.name)
    });
  }, [existingReportSelected]); // eslint-disable-line react-hooks/exhaustive-deps


  const getPreviousVersionDate = () => {
    if (!existingReportSelected || state.additionalReportState.activePreviousEditIndex === undefined) return '';

    const allDates: string[] = [
      existingReportSelected.date.split('T')[0],
      ...(existingReportSelected.previousEdits ?? []).map(edit => edit.dateOverwritten.split('T')[0]),
    ];

    return getDateLocaleFormat(allDates[state.additionalReportState.activePreviousEditIndex]);
  };


  const isReadOnlyMode = existingReportSelected !== undefined && !state.additionalReportState.isEditMode;


  const clubColor = getClubColor(userConfig?.club ?? '');


  return (
    <div className='document-container'>

      {existingReportSelected && (
        <Dialog
          open={state.additionalReportState.isEditHistoryModalOpen}
          onClose={() => dispatch({ type: 'SET_IS_EDIT_HISTORY_MODAL_OPEN', payload: false })}
          PaperProps={{ sx: { borderRadius: '0px' } }}>
          <ReportEditHistoryModal
            dispatch={dispatch}
            existingReportSelected={existingReportSelected}
            activePreviousEditIndex={state.additionalReportState.activePreviousEditIndex}
          />
        </Dialog>
      )}

      <div className='fast-fade-in'>
        <div className='document-close-icon' onClick={handleCloseIconClick}>
          <CloseIcon style={{ fontSize: 24 }} />
        </div>

        {existingReportSelected
          && existingReportSelected.previousEdits
          && existingReportSelected.previousEdits.length > 0
          && !state.additionalReportState.isEditMode
          && (
            <div
              className={
                'document-close-icon report-history-icon' + (
                  state.additionalReportState.activePreviousEditIndex !== undefined ? ' report-history-icon-active' : ''
                ) + ' fast-fade-in'
              }
              onClick={() => dispatch({ type: 'SET_IS_EDIT_HISTORY_MODAL_OPEN', payload: true })}
              title={translate('showEditHistory', userConfig?.language)}
            >
              <LibraryBooksIcon style={{ fontSize: 18, color: state.additionalReportState.activePreviousEditIndex !== undefined ? clubColor : undefined }} />
            </div>
          )}

        {isArchiveView && playerTableData && (
          <div className='report-player-table-container' key={existingReportSelected?.id ?? 'newReport'}>
            <div className='fade-in'>
              <PlayerSimpleTable
                data={[playerTableData]}
                onPlayerClick={onPlayerClick}
                tableType={'archiveReportInfo'}
              />
            </div>
          </div>
        )}

        {existingReportSelected && !state.additionalReportState.isEditMode && (
          <div className={'document-title' + (isArchiveView ? ' report-title-archive' : '')}>

            {translate(existingReportSelected.reportType, userConfig?.language)}

            <div className='document-title-divider'>&nbsp;</div>

            <div className='document-title-info'>
              {allUsersWithSameClub[existingReportSelected.userEmail] ? allUsersWithSameClub[existingReportSelected.userEmail].name + ', ' : ''}
              {existingReportSelected.date && getDateLocaleFormat(existingReportSelected.date.split('T')[0])}
            </div>
          </div>
        )}

        {(newReportTypeSelected || state.additionalReportState.isEditMode) && (
          <div className={'document-title' + (isArchiveView ? ' report-title-archive' : '')}>
            {translate(newReportTypeSelected ? ('new' + newReportTypeSelected) : ('edit' + state.reportType), userConfig?.language)}
          </div>
        )}

        <div className='document-horizontal-divider document-horizontal-top-divider'>&nbsp;</div>

        <div className='document-horizontal-divider document-horizontal-bottom-divider'>&nbsp;</div>

        <div className='document-submit-section'>
          {!isLoading && !isReadOnlyMode && userConfig && (
            <div
              className='document-submit-button'
              onClick={(!submitButtonDisabled) ? handleSubmitReport : undefined}
            >
              <div className='document-submit-button-text'>
                {translate(state.additionalReportState.isEditMode ? 'confirmEdits' : ('submit' + state.reportType), userConfig.language)}
              </div>
              <div className='document-submit-button-icon'>
                {state.additionalReportState.isEditMode
                  ? <CheckIcon style={{ fontSize: 21 }} />
                  : <ArrowRightAltIcon style={{ fontSize: 21 }} />}
              </div>
            </div>
          )}

          {!isLoading
            && isReadOnlyMode
            && userConfig
            && existingReportSelected.userEmail === userConfig.email
            && state.additionalReportState.activePreviousEditIndex === undefined
            && (
              <div
                className='document-submit-button'
                style={{ width: 215 }}
                onClick={() => dispatch({ type: 'SET_IS_EDIT_MODE', payload: true })}
              >
                <div className='document-submit-button-text'>
                  {translate('edit' + state.reportType, userConfig.language)}
                </div>
                <div className='document-submit-button-icon'>
                  {<EditNoteIcon style={{ fontSize: 21 }} />}
                </div>
              </div>
            )}

          {!isLoading
            && isReadOnlyMode
            && userConfig
            && existingReportSelected.userEmail === userConfig.email
            && state.additionalReportState.activePreviousEditIndex === undefined
            && (
              <div
                className='document-submit-button'
                style={{ width: 215 }}
                onClick={(!submitButtonDisabled) ? handleDeleteOrRestoreReport : undefined}
              >
                <div className='document-submit-button-text'>
                  {
                    translate(existingReportSelected.isDeleted ? 'restore' : 'delete', userConfig.language) + ' ' +
                    translate(
                      existingReportSelected.reportType === 'Comment'
                        ? 'comment'
                        : existingReportSelected.reportType === 'Attachment'
                          ? 'attachment'
                          : 'report',
                      userConfig.language,
                      true
                    )
                  }
                </div>
                <div className='document-submit-button-icon'>
                  {existingReportSelected.isDeleted
                    ? <RestoreIcon style={{ fontSize: 21 }} />
                    : <DeleteOutlineIcon style={{ fontSize: 21 }} />}
                </div>
              </div>
            )}

          {state.additionalReportState.activePreviousEditIndex !== undefined && (
            <div className='document-submit-text'>
              {translate('aPreviousVersionFrom', userConfig?.language)}
              {getPreviousVersionDate()}
            </div>
          )}

          {isLoading && (
            <div style={{ margin: 'auto', marginBottom: -2 }}>
              <div className='loading-spinner loading-spinner-small'>&nbsp;</div>
            </div>
          )}
        </div>

      </div>

      <div className='document-content-section'>
        {state.reportType === 'MatchReport' && (
          <div className='report-content-left-column fast-fade-in'>

            <div className='report-content-rating-row report-content-input-row report-content-input-top-row'>
              <div className='report-content-input-title'>
                {translate('teamOfPlayer', userConfig?.language)}
              </div>
              <input
                className='document-input-field'
                name='report-input-field-team'
                type='text'
                autoComplete='off'
                value={state.matchReportInfo?.team}
                placeholder={translate('provideTeam', userConfig?.language)}
                onChange={(event) => dispatch({ type: 'SET_TEAM', payload: event.target.value })}
                disabled={isReadOnlyMode}
              />
              {state.additionalReportState.requiredFieldsToShow.teamShowRequired && (
                <div className='report-field-required report-input-required fast-fade-in'>
                  *
                </div>
              )}
            </div>

            <div className='report-content-rating-row report-content-input-row'>
              <div className='report-content-input-title'>
                {translate('opponentTeam', userConfig?.language)}
              </div>
              <input
                className='document-input-field'
                name='report-input-field-opponent'
                type='text'
                autoComplete='off'
                value={state.matchReportInfo?.opponent}
                placeholder={translate('provideTeam', userConfig?.language)}
                onChange={(event) => dispatch({ type: 'SET_OPPONENT', payload: event.target.value })}
                disabled={isReadOnlyMode}
              />
              {state.additionalReportState.requiredFieldsToShow.opponentShowRequired && (
                <div className='report-field-required report-input-required fast-fade-in'>
                  *
                </div>
              )}
            </div>

            <div className='report-content-rating-row report-content-input-row'>
              <div className='report-content-input-title'>
                {translate('matchDate', userConfig?.language)}
              </div>
              <div>
                <DatePicker
                  className='document-input-field'
                  selected={state.matchReportInfo?.matchDate ? new Date(state.matchReportInfo.matchDate) : null}
                  onChange={(date: Date | null) => dispatch({ type: 'SET_MATCH_DATE', payload: date })}
                  locale={locale}
                  dateFormat={dateFormat}
                  popperPlacement='bottom-start'
                  disabled={isReadOnlyMode}
                />
              </div>
              {state.additionalReportState.requiredFieldsToShow.matchDateShowRequired && (
                <div className='report-field-required report-input-required fast-fade-in'>
                  *
                </div>
              )}
            </div>

            <div className='document-text-area-container report-text-area-container-match-report'>
              <textarea
                className='document-text-area'
                value={state.comment}
                onChange={(event) => dispatch({ type: 'SET_COMMENT', payload: event.target.value })}
                placeholder={translate(existingReportSelected ? 'noCommentsProvided' : 'provideComments', userConfig?.language)}
                disabled={isReadOnlyMode}
              />
            </div>
          </div>
        )}

        {state.reportType === 'ScoutingReport' && (
          <div className='report-content-left-column fast-fade-in'>
            <div
              className={
                'document-text-area-container report-text-area-container-scouting-report-' +
                (state.fileNames.length > 1 ? 'multiple' : state.fileNames.length)
              }
            >
              <textarea
                className='document-text-area'
                value={state.comment}
                onChange={(event) => dispatch({ type: 'SET_COMMENT', payload: event.target.value })}
                placeholder={translate(existingReportSelected ? 'noCommentsProvided' : 'provideComments', userConfig?.language)}
                disabled={isReadOnlyMode}
              />
            </div>

            <div
              className={
                'document-file-container report-file-container' +
                (state.fileNames.length < 2 ? (' report-file-container-' + state.fileNames.length) : '')
              }
            >

              <div
                className={'document-input-title report-file-input-title-' + (state.fileNames.length > 1 ? 'multiple' : state.fileNames.length)}
                style={{ width: 'fit-content', position: 'relative' }}
              >
                <div style={{ paddingLeft: state.fileNames.length > 0 ? 10 : undefined }}>
                  {translate(isReadOnlyMode && state.fileNames.length === 0 ? 'noAttachments' : 'attachments', userConfig?.language)}
                </div>

                {state.fileNames.length > 0 && (
                  <div style={{ position: 'absolute', left: -16, top: 0.5, fontSize: 12, color: '#ffffffaa' }}>
                    ({state.fileNames.length})
                  </div>
                )}

                {!isReadOnlyMode && (
                  <div style={{ position: 'absolute', right: -32, top: -3, zIndex: 1 }}>
                    <FileUpload handleFileUpload={handleUploadFile} language={userConfig?.language ?? 'en'} />
                  </div>
                )}
              </div>

              {state.fileUrls && state.fileUrls.length > 0 && (
                <div
                  className='document-file-container-existing-files-container'
                  style={{ height: state.fileUrls.length > 1 ? undefined : 55, paddingTop: state.fileUrls.length > 1 ? undefined : 10 }}
                >
                  <div className='document-file-container-existing-files report-file-container-existing-files'>
                    {state.fileUrls.map((fileUrl, index) => {
                      if (state.fileNames.length <= index) return null;

                      const fileName = state.fileNames[index];
                      return (
                        <div
                          key={index}
                          className='document-file-container-existing-file report-file-container-existing-file'
                          onClick={() => openPdfModal(fileUrl, fileName)}
                        >
                          {!isReadOnlyMode && (
                            <div
                              className='document-content-icon document-file-container-existing-file-close-icon'
                              onClick={(event) => handleRemoveFile(event, fileUrl, fileName)}
                            >
                              <CloseIcon style={{ fontSize: 18 }} />
                            </div>
                          )}
                          <PictureAsPdfIcon style={{ fontSize: 18, marginRight: 6 }} />
                          {getShortenedFileName(fileName, 22)}
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          </div>
        )}

        {(state.reportType === 'MatchReport' || state.reportType === 'ScoutingReport') && (
          <div className='report-content-right-column'>

            {newReportTypeSelected === 'ScoutingReport' && averageMatchReportScores && Object.keys(averageMatchReportScores).length > 0 && (
              <div className='report-content-average-title match-report-content-average-title fast-fade-in'>
                {translate('averageMatchReports', userConfig?.language)}
              </div>
            )}

            <ReportScores
              scores={state.scores}
              handleRatingChange={isReadOnlyMode
                ? undefined
                : (scoreKey, value) => dispatch({ type: 'SET_SCORE', payload: { scoreKey: scoreKey, scoreValue: value } })
              }
              averageMatchReportScores={state.reportType === 'ScoutingReport' ? averageMatchReportScores : undefined}
              requiredScoresToShow={state.additionalReportState.requiredFieldsToShow}
            />
          </div>
        )}

        {state.reportType === 'Comment' && (
          <div className='document-text-area-container'>
            <textarea
              className='document-text-area'
              value={state.comment}
              onChange={(event) => dispatch({ type: 'SET_COMMENT', payload: event.target.value })}
              placeholder={translate(existingReportSelected ? 'noCommentsProvided' : 'provideCommentOrInformation', userConfig?.language)}
              disabled={isReadOnlyMode}
            />
          </div>
        )}

        {state.reportType === 'Attachment' && (
          <div className='full-size-container flex-column'>

            <div className='document-text-area-container' style={{ height: '62%', position: 'absolute', top: '3%' }}>
              {!isReadOnlyMode && (
                <div style={{ margin: 'auto', paddingTop: 20, paddingBottom: 10 }}>
                  <FileUpload
                    handleFileUpload={handleUploadFile}
                    language={userConfig?.language ?? 'en'}
                    isButton={true}
                  />
                </div>
              )}

              <div className='document-file-container-existing-files report-file-container-existing-files-attachment'>
                {state.fileUrls && state.fileUrls.map((fileUrl, index) => {
                  if (!state.fileNames || state.fileNames.length <= index) return null;

                  const fileName = state.fileNames[index];
                  return (
                    <div
                      key={index}
                      className='document-file-container-existing-file'
                      style={{ width: 'fit-content', maxWidth: 'none', marginTop: 8, marginBottom: 8, fontSize: 13, padding: '5px 8px' }}
                      onClick={() => openPdfModal(fileUrl, fileName)}
                    >
                      {!isReadOnlyMode && (
                        <div
                          className='document-content-icon document-file-container-existing-file-close-icon'
                          onClick={(event) => handleRemoveFile(event, fileUrl, fileName)}
                        >
                          <CloseIcon style={{ fontSize: 18 }} />
                        </div>
                      )}
                      <PictureAsPdfIcon style={{ fontSize: 18, marginRight: 8 }} />
                      {getShortenedFileName(fileName, 60)}
                    </div>
                  );
                })}
              </div>
            </div>

            <div className='document-text-area-container' style={{ height: '28%', position: 'absolute', bottom: '4%' }}>
              <textarea
                className='document-text-area'
                value={state.comment}
                onChange={(event) => dispatch({ type: 'SET_COMMENT', payload: event.target.value })}
                placeholder={translate(existingReportSelected ? 'noCommentsProvided' : 'provideComments', userConfig?.language)}
                disabled={isReadOnlyMode}
              />
            </div>
          </div>
        )}

      </div>

    </div >
  );
};

import './navigation.css';

import { useEffect, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { navigationPropsState } from '../../recoil/atoms/navigationPropsState';
import { useSetAndTrackNavigationPropsState } from '../../recoil/hooks/useSetAndTrackNavigationPropsState';
import { userConfigState } from '../../recoil/atoms/userConfigState';
import { selectedPlayerState } from '../../recoil/atoms/selectedPlayerState';
import { updateUserConfig } from '../../services/firestore/users';
import { useWindowSize } from '../../../common/hooks/WindowSize';
import { useTrackEvent } from '../../services/server/analytics/useTrackEvent';

import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';
import StarIcon from '@mui/icons-material/Star';
import HomeIcon from '@mui/icons-material/Home';
import SettingsIcon from '@mui/icons-material/Settings';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import GroupIcon from '@mui/icons-material/Group';
import ConstructionIcon from '@mui/icons-material/Construction';
import PublicIcon from '@mui/icons-material/Public';
import FolderIcon from '@mui/icons-material/Folder';
import PaidIcon from '@mui/icons-material/Paid';
// import TimelineIcon from '@mui/icons-material/Timeline';

import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import { translate } from '../../../common/language/translations';
import { NavigationBackgroundSvg } from '../../svg/NavigationBackgroundSvg';
import { getClubLogo, getClubLogoFormat, getClubLogoShadow, getClubSecondaryColor } from '../../utils/configUtils';
import { UserConfig } from '../../types';
import {
  userHasEconomyAccess,
  // userHasFullOwnTeamAccess,
  userHasScoutAccess
} from '../../utils/userUtils';


export const getNavigationWidth = (userConfig: UserConfig | null) => {
  if (userConfig && userConfig.isNavigationExpanded) {
    if (userConfig.language === 'no') return 133;
    if (userConfig.language === 'en') return 143;
    if (userConfig.language === 'se') return 143;
    if (userConfig.language === 'dk') return 138;
  }
  return 64;
};


export const Navigation: React.FC = () => {

  const navigationProps = useRecoilValue(navigationPropsState);
  const setAndTrackNavigationProps = useSetAndTrackNavigationPropsState();
  const trackEvent = useTrackEvent();

  const userConfig = useRecoilValue(userConfigState);
  const hasScoutAccess = userHasScoutAccess(userConfig);
  const hasEconomyAccess = userHasEconomyAccess(userConfig);
  // const hasFullOwnTeamAccess = userHasFullOwnTeamAccess(userConfig);

  const isNavigationExpanded = userConfig ? userConfig.isNavigationExpanded : true;
  const navigationWidth = getNavigationWidth(userConfig);

  const { height } = useWindowSize();

  const setSelectedPlayer = useSetRecoilState(selectedPlayerState);

  const [isNavigationExpanding, setIsNavigationExpanding] = useState(false);
  const [tabLineTop, setTabLineTop] = useState<number>(100);

  const [recentlyClickedTab, setRecentlyClickedTab] = useState('');


  const updateTabLineTop = (newActiveTab: string) => {
    const element = document.getElementById(newActiveTab);
    if (element) {
      const heightOffset = height <= 580 ? (1 + height / 160) : 0; // something changes when height is 580 or below (?)
      const elementPosition = element.getBoundingClientRect().top + 5.25 + heightOffset - (height / 50) - (isNavigationExpanded ? 0.5 : 0);
      setTabLineTop(elementPosition);
    }
  };


  const handleNavigation = (newActiveTab: string) => {
    setSelectedPlayer(undefined);
    if (newActiveTab !== navigationProps.activeTab) {
      setAndTrackNavigationProps({ activeTab: newActiveTab });

      setRecentlyClickedTab(newActiveTab);
      setTimeout(() => {
        setRecentlyClickedTab('');
      }, 1000);
    }
    updateTabLineTop(newActiveTab);
  };


  const handleNavigationExpansion = () => {
    if (userConfig) {
      trackEvent('NavigationExpansionToggled');
      updateUserConfig(userConfig.email, 'isNavigationExpanded', !isNavigationExpanded);
    }
  };


  useEffect(() => {
    setIsNavigationExpanding(true);
    let count = 0;

    // Duration must exceed var(--platform-navigation-expansion-transition)
    const maxCount = 26;
    const interval = 10;

    const intervalId = setInterval(() => {
      updateTabLineTop(navigationProps.activeTab);
      count++;
      if (count >= maxCount) {
        setIsNavigationExpanding(false);
        clearInterval(intervalId);
      }
    }, interval);

    const clear = () => {
      setIsNavigationExpanding(false);
      clearInterval(intervalId);
    };
    return () => clear();
  }, [isNavigationExpanded]); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    let count = 0;
    const maxCount = 10;
    const interval = 10;

    const intervalId = setInterval(() => {
      updateTabLineTop(navigationProps.activeTab);
      count++;
      if (count >= maxCount) {
        clearInterval(intervalId);
      }
    }, interval);

    return () => clearInterval(intervalId);
  }, [height]); // eslint-disable-line react-hooks/exhaustive-deps


  const getLogoClassName = () => {
    let className = 'navigation-logo-section';

    const logoFormat = userConfig ? getClubLogoFormat(userConfig.club) : 'square';
    className += isNavigationExpanded
      ? (' navigation-logo-section-' + logoFormat + '-expanded')
      : (' navigation-logo-section-' + logoFormat);

    return className;
  };


  useEffect(() => {
    if (navigationProps) {
      updateTabLineTop(navigationProps.activeTab);
    }
  }, [navigationProps]); // eslint-disable-line react-hooks/exhaustive-deps


  const clubSecondaryColor = getClubSecondaryColor(userConfig?.club ?? '');

  const logoShadow = getClubLogoShadow(userConfig?.club ?? '');


  return (
    <div className='navigation-container' style={{ width: navigationWidth }}>

      <div className='full-size-container absolute-container'>
        <NavigationBackgroundSvg />
      </div>

      <div className='navigation-tabs'>

        <div
          className={'navigation-line fade-in' + (isNavigationExpanding ? ' navigation-line-expanding' : '')}
          style={{ top: tabLineTop, left: navigationWidth - 10, backgroundColor: clubSecondaryColor }}
        >
          &nbsp;
        </div>

        <div className={getLogoClassName()}>
          {logoShadow && <div className={'navigation-logo-section-shadow-overlay' + ' navigation-logo-section-shadow-overlay-' + logoShadow}>&nbsp;</div>}

          <img src={getClubLogo(userConfig?.club ?? '')} alt='logo' className='full-size-container' style={{ position: 'absolute', zIndex: '20' }} />
        </div>

        <div className='navigation-space'>&nbsp;</div>
        <div className={isNavigationExpanded ? 'navigation-divider navigation-divider-expanded' : 'navigation-divider'}>&nbsp;</div>

        <div
          id='home'
          style={{ color: navigationProps.activeTab === 'home' ? clubSecondaryColor : '#efefef', width: navigationWidth }}
          className={
            'navigation-tab-link'
            + (isNavigationExpanded ? ' navigation-tab-link-expanded' : '')
            + (navigationProps.activeTab === 'home' ? ' navigation-tab-link-active' : '')
          }
          onClick={() => handleNavigation('home')}
        >
          <div className='navigation-tab-content'>
            <div className={'navigation-tab-icon' + (isNavigationExpanded ? ' navigation-tab-icon-expanded' : '')}>
              <HomeIcon style={{ fontSize: 22 }} />
            </div>
            {isNavigationExpanded && !isNavigationExpanding && (
              <div className='navigation-tab-text fade-in'>
                {translate('home', userConfig?.language)}
              </div>
            )}
            {!isNavigationExpanded && recentlyClickedTab !== 'home' && (
              <div className='navigation-tab-hover-text'>
                {translate('home', userConfig?.language)}
              </div>
            )}
          </div>
        </div>

        {hasScoutAccess && <div className={isNavigationExpanded ? 'navigation-divider navigation-divider-expanded' : 'navigation-divider'}>&nbsp;</div>}

        {hasScoutAccess && (
          <div
            id='masterTeam'
            style={{ color: navigationProps.activeTab === 'masterTeam' ? clubSecondaryColor : '#efefef', width: navigationWidth }}
            className={
              'navigation-tab-link' +
              (isNavigationExpanded ? ' navigation-tab-link-expanded' : '') +
              (navigationProps.activeTab === 'masterTeam' ? ' navigation-tab-link-active' : '')
            }
            onClick={() => handleNavigation('masterTeam')}
          >
            <div className='navigation-tab-content'>
              <div className={'navigation-tab-icon' + (isNavigationExpanded ? ' navigation-tab-icon-expanded' : '')}>
                <StarIcon style={{ fontSize: 22 }} />
              </div>
              {isNavigationExpanded && !isNavigationExpanding && (
                <div className='navigation-tab-text fade-in'>
                  {translate('masterTeam', userConfig?.language)}
                </div>
              )}
              {!isNavigationExpanded && recentlyClickedTab !== 'masterTeam' && (
                <div className='navigation-tab-hover-text'>
                  {translate('masterTeam', userConfig?.language)}
                </div>
              )}
            </div>
          </div>
        )}

        {hasScoutAccess && (
          <div
            id='shadowTeam'
            style={{ color: navigationProps.activeTab === 'shadowTeam' ? clubSecondaryColor : '#efefef', width: navigationWidth }}
            className={
              'navigation-tab-link'
              + (isNavigationExpanded ? ' navigation-tab-link-expanded' : '')
              + (navigationProps.activeTab === 'shadowTeam' ? ' navigation-tab-link-active' : '')
            }
            onClick={() => handleNavigation('shadowTeam')}
          >
            <div className='navigation-tab-content'>
              <div className={'navigation-tab-icon' + (isNavigationExpanded ? ' navigation-tab-icon-expanded' : '')}>
                <SportsSoccerIcon style={{ fontSize: 22 }} />
              </div>
              {isNavigationExpanded && !isNavigationExpanding && (
                <div className='navigation-tab-text fade-in'>
                  {translate('shadowTeam', userConfig?.language)}
                </div>
              )}
              {!isNavigationExpanded && recentlyClickedTab !== 'shadowTeam' && (
                <div className='navigation-tab-hover-text'>
                  {translate('shadowTeam', userConfig?.language)}
                </div>
              )}
            </div>
          </div>
        )}

        {hasScoutAccess && (
          <div
            id='scoutTeams'
            style={{ color: navigationProps.activeTab === 'scoutTeams' ? clubSecondaryColor : '#efefef', width: navigationWidth }}
            className={
              'navigation-tab-link'
              + (isNavigationExpanded ? ' navigation-tab-link-expanded' : '')
              + (navigationProps.activeTab === 'scoutTeams' ? ' navigation-tab-link-active' : '')
            }
            onClick={() => handleNavigation('scoutTeams')}
          >
            <div className='navigation-tab-content'>
              <div className={'navigation-tab-icon' + (isNavigationExpanded ? ' navigation-tab-icon-expanded' : '')}>
                <PublicIcon style={{ fontSize: 22 }} />
              </div>
              {isNavigationExpanded && !isNavigationExpanding && (
                <div className='navigation-tab-text fade-in'>
                  {translate('scoutTeams', userConfig?.language)}
                </div>
              )}
              {!isNavigationExpanded && recentlyClickedTab !== 'scoutTeams' && (
                <div className='navigation-tab-hover-text'>
                  {translate('scoutTeams', userConfig?.language)}
                </div>
              )}
            </div>
          </div>
        )}

        {hasScoutAccess &&
          <div className={isNavigationExpanded ? 'navigation-divider navigation-divider-expanded' : 'navigation-divider'}>&nbsp;</div>}

        {hasScoutAccess && (
          <div
            id='scouting'
            style={{ color: navigationProps.activeTab === 'scouting' ? clubSecondaryColor : '#efefef', width: navigationWidth }}
            className={
              'navigation-tab-link'
              + (isNavigationExpanded ? ' navigation-tab-link-expanded' : '')
              + (navigationProps.activeTab === 'scouting' ? ' navigation-tab-link-active' : '')
            }
            onClick={() => handleNavigation('scouting')}
          >
            <div className='navigation-tab-content'>
              <div className={'navigation-tab-icon' + (isNavigationExpanded ? ' navigation-tab-icon-expanded' : '')}>
                <PersonSearchIcon style={{ fontSize: 22 }} />
              </div>
              {isNavigationExpanded && !isNavigationExpanding && (
                <div className='navigation-tab-text fade-in'>
                  {translate('scouting', userConfig?.language)}
                </div>
              )}
              {!isNavigationExpanded && recentlyClickedTab !== 'scouting' && (
                <div className='navigation-tab-hover-text'>
                  {translate('scouting', userConfig?.language)}
                </div>
              )}
            </div>
          </div>
        )}

        {hasScoutAccess && (
          <div
            id='archive'
            style={{ color: navigationProps.activeTab === 'archive' ? clubSecondaryColor : '#efefef', width: navigationWidth }}
            className={
              'navigation-tab-link'
              + (isNavigationExpanded ? ' navigation-tab-link-expanded' : '')
              + (navigationProps.activeTab === 'archive' ? ' navigation-tab-link-active' : '')
            }
            onClick={() => handleNavigation('archive')}
          >
            <div className='navigation-tab-content'>
              <div className={'navigation-tab-icon' + (isNavigationExpanded ? ' navigation-tab-icon-expanded' : '')}>
                <FolderIcon style={{ fontSize: 22 }} />
              </div>
              {isNavigationExpanded && !isNavigationExpanding && (
                <div className='navigation-tab-text fade-in'>
                  {translate('archive', userConfig?.language)}
                </div>
              )}
              {!isNavigationExpanded && recentlyClickedTab !== 'archive' && (
                <div className='navigation-tab-hover-text'>
                  {translate('archive', userConfig?.language)}
                </div>
              )}
            </div>
          </div>
        )}

        <div id='navigation-divider' className={isNavigationExpanded ? 'navigation-divider navigation-divider-expanded' : 'navigation-divider'}>&nbsp;</div>

        <div
          id='ownTeam'
          style={{ color: navigationProps.activeTab === 'ownTeam' ? clubSecondaryColor : '#efefef', width: navigationWidth }}
          className={
            'navigation-tab-link'
            + (isNavigationExpanded ? ' navigation-tab-link-expanded' : '')
            + (navigationProps.activeTab === 'ownTeam' ? ' navigation-tab-link-active' : '')
          }
          onClick={() => handleNavigation('ownTeam')}
        >
          <div className='navigation-tab-content'>
            <div className={'navigation-tab-icon' + (isNavigationExpanded ? ' navigation-tab-icon-expanded' : '')}>
              <GroupIcon style={{ fontSize: 22 }} />
            </div>
            {isNavigationExpanded && !isNavigationExpanding && (
              <div className='navigation-tab-text fade-in'>
                {translate('ownTeam', userConfig?.language)}
              </div>
            )}
            {!isNavigationExpanded && recentlyClickedTab !== 'ownTeam' && (
              <div className='navigation-tab-hover-text'>
                {translate('ownTeam', userConfig?.language)}
              </div>
            )}
          </div>
        </div>

        {/* {hasFullOwnTeamAccess && (
          <div
            id='planner'
            style={{ color: navigationProps.activeTab === 'planner' ? clubSecondaryColor : '#efefef', width: navigationWidth }}
            className={'navigation-tab-link'
              + (isNavigationExpanded ? ' navigation-tab-link-expanded' : '')
              + (navigationProps.activeTab === 'planner' ? ' navigation-tab-link-active' : '')
            }
            onClick={() => handleNavigation('planner')}
          >
            <div className='navigation-tab-content'>
              <div className={'navigation-tab-icon' + (isNavigationExpanded ? ' navigation-tab-icon-expanded' : '')}>
                <TimelineIcon style={{ fontSize: 22 }} />
              </div>
              {isNavigationExpanded && !isNavigationExpanding && (
                <div className='navigation-tab-text fade-in'>
                  {translate('planner', userConfig?.language)}
                </div>
              )}
              {!isNavigationExpanded && recentlyClickedTab !== 'squadBuilder' && (
                <div className='navigation-tab-hover-text'>
                  {translate('planner', userConfig?.language)}
                </div>
              )}
            </div>
          </div>
        )} */}

        {hasScoutAccess && (
          <div
            id='squadBuilder'
            style={{ color: navigationProps.activeTab === 'squadBuilder' ? clubSecondaryColor : '#efefef', width: navigationWidth }}
            className={'navigation-tab-link'
              + (isNavigationExpanded ? ' navigation-tab-link-expanded' : '')
              + (navigationProps.activeTab === 'squadBuilder' ? ' navigation-tab-link-active' : '')
            }
            onClick={() => handleNavigation('squadBuilder')}
          >
            <div className='navigation-tab-content'>
              <div className={'navigation-tab-icon' + (isNavigationExpanded ? ' navigation-tab-icon-expanded' : '')}>
                <ConstructionIcon style={{ fontSize: 22 }} />
              </div>
              {isNavigationExpanded && !isNavigationExpanding && (
                <div className='navigation-tab-text fade-in'>
                  {translate('squadBuilder', userConfig?.language)}
                </div>
              )}
              {!isNavigationExpanded && recentlyClickedTab !== 'squadBuilder' && (
                <div className='navigation-tab-hover-text'>
                  {translate('squadBuilder', userConfig?.language)}
                </div>
              )}
            </div>
          </div>
        )}

        {!hasScoutAccess &&
          <div id='navigation-divider' className={isNavigationExpanded ? 'navigation-divider navigation-divider-expanded' : 'navigation-divider'}>&nbsp;</div>}

        {hasEconomyAccess && (
          <div
            id='economy'
            style={{ color: navigationProps.activeTab === 'economy' ? clubSecondaryColor : '#efefef', width: navigationWidth }}
            className={
              'navigation-tab-link'
              + (isNavigationExpanded ? ' navigation-tab-link-expanded' : '')
              + (navigationProps.activeTab === 'economy' ? ' navigation-tab-link-active' : '')
            }
            onClick={() => handleNavigation('economy')}
          >
            <div className='navigation-tab-content'>
              <div className={'navigation-tab-icon' + (isNavigationExpanded ? ' navigation-tab-icon-expanded' : '')}>
                <PaidIcon style={{ fontSize: 22 }} />
              </div>
              {isNavigationExpanded && !isNavigationExpanding && (
                <div className='navigation-tab-text fade-in'>
                  {translate('clauses', userConfig?.language)}
                </div>
              )}
              {!isNavigationExpanded && recentlyClickedTab !== 'economy' && (
                <div className='navigation-tab-hover-text'>
                  {translate('clauses', userConfig?.language)}
                </div>
              )}
            </div>
          </div>
        )}

        <div id='navigation-divider' className={isNavigationExpanded ? 'navigation-divider navigation-divider-expanded' : 'navigation-divider'}>&nbsp;</div>

        <div
          id='settings'
          style={{ color: navigationProps.activeTab === 'settings' ? clubSecondaryColor : '#efefef', width: navigationWidth }}
          className={
            'navigation-tab-link'
            + (isNavigationExpanded ? ' navigation-tab-link-expanded' : '')
            + (navigationProps.activeTab === 'settings' ? ' navigation-tab-link-active' : '')
          }
          onClick={() => handleNavigation('settings')}
        >
          <div className='navigation-tab-content'>
            <div className={'navigation-tab-icon' + (isNavigationExpanded ? ' navigation-tab-icon-expanded' : '')}>
              <SettingsIcon style={{ fontSize: 22 }} />
            </div>
            {isNavigationExpanded && !isNavigationExpanding && (
              <div className='navigation-tab-text fade-in'>
                {translate('settings', userConfig?.language)}
              </div>
            )}
            {!isNavigationExpanded && recentlyClickedTab !== 'settings' && (
              <div className='navigation-tab-hover-text'>
                {translate('settings', userConfig?.language)}
              </div>
            )}
          </div>
        </div>

        <div className='navigation-divider'>&nbsp;</div>
        <div className='navigation-space'>&nbsp;</div>

        <div
          className='navigation-expand-icon'
          onClick={handleNavigationExpansion}
        >
          {isNavigationExpanded
            ? <KeyboardArrowLeftIcon style={{ fontSize: 22 }} />
            : <KeyboardArrowRightIcon style={{ fontSize: 22 }} />
          }
        </div>

      </div>
    </div>
  );
};
